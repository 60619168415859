<script lang='ts'>
  import {onMount} from "svelte";

  import {fetchData} from "@/helpers/fetchHelpers";
  import {formatScriptLine} from "@/helpers/mixHelpers";
  import {notifier} from "@/helpers/notifier";

  import ScriptBatchDetails from "@/components/advanced/ScriptBatchDetails.svelte";
  import Breadcrumb from "@/components/ui/Breadcrumb.svelte";

  type ScriptLine = {
    content: string;
    id: number;
    line_order: number;
  }

  export let iso = ''
  export let id: number

  let script: any = {}
  const givenAnswers: Record<number, string> = {}
  let allAnswered = false
  let finished = false
  let activeLineIndexes: number[] = []
  let translated: ScriptLine[] = []
  let stats: any
  const limit = location.hostname.includes('lang.com') ? 2 : 10

  onMount(() => {
    loadScript()
  })

  const loadScript = async () => {
    const data = await fetchData('main/scriptsPracticeLoad', {id})
    script = data.script
    activeLineIndexes = `${data.activeBatch.active_lines}`?.split(',').map(Number)
    translated = data.translated
  }

  const getAnswersCount = () => Object.values(givenAnswers).filter((answer: string) => answer.length).length

  const save = async () => {
    if(getAnswersCount() < limit && limit < activeLineIndexes.length) {
      notifier.error(`You need to answer at least ${limit} questions`)
      return
    }
    // if(allAnswered) {
    finished = true // block from editing
    // }
    const data =await fetchData('main/scriptsPracticeSave', {allAnswered, answers: givenAnswers, id})
    const batchId = data.batchId
    stats = await fetchData('main/scriptsBatchDetails', {batchId, id})
  }

  const updateLabel = () => {
    allAnswered = getAnswersCount() === activeLineIndexes.length
  }
</script>
<h2>Practice</h2>
<p>Page for practicing language using language scripts</p>

<Breadcrumb breads={[
  {text:'',url:`/${iso}`},
  {text:'Language scripts',url:`/${iso}/advanced/language-scripts`},
  {text:`${script.title || 'unknown script'}`, url: `/${iso}/advanced/learnScript/${id}`},
  {text:`Practice` }
  ]} />

{#if finished}
  <p>You have finished this script</p>
  {#if stats}
  <ScriptBatchDetails details={stats} />
  {/if}
{:else}
  <table>
    {#each activeLineIndexes as lineOrder}
      {@const entry = translated[lineOrder] || {}}
      <tr>
        <td>{@html formatScriptLine(entry?.content)}</td>
        <td class="no-padding">
          <textarea bind:value={givenAnswers[lineOrder]}
            on:input={updateLabel}
          />
        </td>
      </tr>
    {/each}
  </table>
  <button type="button" on:click={save}>{allAnswered ? 'Save' : 'Save draft'}</button>
{/if}
<style lang='scss'>
  td{
    width: 50%;
    font-size: 1.6rem;
    &.no-padding{
      padding: 0;
    }
    > :global(h4){
      font-size: 1.8rem;
    }
    > textarea {
      width: 100%;
      height: 4rem;
      padding: 0.2rem;
      border-radius: 0;
    }
  }
</style>
