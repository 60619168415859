<script lang='ts'>
  import { link } from 'svelte-routing'

  import { _ } from '@/helpers/i18n'

  import LangoidSocialLinks from '@/components/LangoidSocialLinks.svelte'
</script>
<footer>
  <div class='footer-container'>
    <div class='main-links'>
      <div class='links-section'>
        <h5 class='footerHeading'>CONTENT</h5>
        <a href='/forum' use:link>{$_('forum.forum')}</a>
      </div>
      <div class='links-section'>
        <h5 class='footerHeading'>INFORMATION</h5>
        <a href='/static/about-us.html'>About us</a>
        <a href='/static/jobs.html'>Jobs</a>
        <a href='/static/contact-us.html'>Contact us</a>
      </div>
      <div class='links-section'>
        <h5 class='footerHeading'>LEGAL</h5>
        <a href='/static/privacy-policy.html'>{$_('homeNotLogged.privacyPolicy')}</a>
        <a href='/static/terms.html'>Terms</a>
        <a href='/static/cookie-policy.html'>Cookie policy</a>
      </div>
      <div class='links-section'>
        <h5 class='footerHeading'>SOCIAL MEDIA</h5>
        <LangoidSocialLinks />
      </div>
    </div>
    <small>{$_('homeNotLogged.copyright', {
      values: { year: (new Date()).getFullYear() }
    })}</small>
  </div>
</footer>

<style lang='scss'>
  footer {
    padding: 2rem 0 1rem 0;
  }

  .footer-container {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;

    > small {
      display: block;
      margin: 2.8rem 0;
      padding-top: 2rem;
      text-align: center;
      border-top: 0.1rem solid var(--Gray-Light);
    }
  }

  .main-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .links-section {
    display: flex;
    flex-direction: column;

    > a {
      margin: 1rem 0;
    }

    &:last-child {
      display: block;

      > a {
        margin-right: 1rem;
      }
    }

    > .footerHeading {
      margin: 2rem 0;
      font-weight: bold;
      color: var(--text-primary-color);
    }
  }

  @media (max-width: 768px) {
    .main-links {
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }

    .links-section {
      padding-left: 4.8rem;

      > a {
        margin: 0.6rem 0;
      }
    }
  }
</style>
