<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { links, useLocation } from 'svelte-routing'

  import { EXECUTION_TIME_HACK, ISO_LENGTH } from '@/config/constants'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { countChatMessages } from '@/helpers/chatHelpers'
  import { _ } from '@/helpers/i18n'
  import { setMeta } from '@/helpers/metaTags'
  import { toolsState } from '@/helpers/mixHelpers'
  import { navigateWithReset } from '@/helpers/navigateWithReset'
  import { calculateLocationGroup } from '@/helpers/urlHelpers'

  import Icon from '@/components/icons/Icon.svelte'

  import { LanguageStatus } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { currentLevelForLessons, feat, languagesStore, mixStore, toolsMenuStore, userStorredConfig } from '@/store'

  let iso: string
  const lessonLevel: string = $currentLevelForLessons

  setTimeout(() => {
    prepareLocationForMeta(location.pathname)
  }, EXECUTION_TIME_HACK)

  let countNew = 0
  const unsubscribe2 = mixStore.subscribe((data: MixedStoreData) => {
    countNew = countChatMessages(data.messagesCounts)
  })

  let menuGroups: string[] = []
  let locationGroup = ''
  const locationStore = useLocation()
  const prepareLocationForMeta = (currentLocation: string) => {
    menuGroups = currentLocation.split('/')
    locationGroup = calculateLocationGroup(currentLocation, menuGroups)
    setMeta(menuGroups, locationGroup)
  }

  let can: Record<string, boolean> = {}
  const unsubscribe3 = locationStore.subscribe(routerData => {
    prepareLocationForMeta(routerData.pathname)
    // console.log('RouterData: ', routerData)
    const tmpIso = routerData.pathname.split('/')[1]
    if (tmpIso?.length === ISO_LENGTH) {
      iso = tmpIso
      can = getAccessValues(tmpIso)
      feat.update(() => can)
    } else {
      // can = {}
      // feat.set({})
    }

    setTimeout(() => {
      prepareLocationForMeta(routerData.pathname)
    }, EXECUTION_TIME_HACK * 2)
  })

  function getAccessValues (currentIso: string) {
    const store = $languagesStore[currentIso]
    if (!store) {
      return {}
    }
    const { features, native, status } = store as LanguageStatus
    return { ...features, native, status }
  }

  const unsubscribe4 = languagesStore.subscribe(async (lang) => {
    if (iso) {
      can = getAccessValues(iso)
    }
  })
  const unsubscribe5 = userStorredConfig.subscribe(async (config) => {
    if (config.id_lang_learning) {
      iso = getIsoFromId(config.id_lang_learning)
      can = getAccessValues(iso)
    }
  })

  onDestroy(() => {
    unsubscribe2()
    unsubscribe3()
    unsubscribe4()
    unsubscribe5()
  })

  function viewSubpages (e: MouseEvent) {
    e.stopPropagation()
    const target = e.currentTarget as HTMLElement
    if (target) {
      target.classList.toggle('-hide')
    }
  }

  function openTools (type: string, tabType: 'word' | 'sentence' = 'word') {
    toolsMenuStore.update(state => toolsState(state, type, tabType))
  }
</script>
{#key iso}
  <ul class='vertical-menu' use:links>
    <li>
      <a class:-active={locationGroup==='home'} href={`/${iso}/home`}>
        <Icon icon='House' />{$_('levelPage.home')}
      </a>
    </li>
    {#if !can.native}
      {#if can.vocabulary}
        <li>
          <a class:-active={locationGroup==='lessons'} href={`/${iso}/lessons/${lessonLevel}`}>
            <Icon icon='Notebook' />{$_('levelPage.lessons')}
          </a>
        </li>
      {/if}
      <li>
        <span
          class='fake-link -hide'
          class:-active={locationGroup === 'tests'}
          role='button'
          tabindex='0'
          on:click={viewSubpages}
          on:keypress={() => {}}
        >
          <Icon icon='Lightning' />{$_('levelPage.tests')}
          <span class='arrow -closed' />
        </span>
        <ul class='dropdown-menu'>
          {#if can.testsWords || can.testsSentences || can.testsAudioSentences}
            {#if can.testsWords}
              <li>
                <a
                  class:-active={menuGroups[2]==='tests' && menuGroups[3] === 'words'}
                  href={`/${iso}/tests/words`}
                  on:click|preventDefault={() => {navigateWithReset(`/${iso}/tests/words`)}}
                >{$_('levelPage.testsWords')}
                </a>
              </li>
            {/if}
            {#if can.testsSentences}
              <li>
                <a
                  class:-active={menuGroups[2]==='tests' && menuGroups[3] === 'srs'}
                  href={`/${iso}/tests/srs`}
                  on:click|preventDefault={() => {navigateWithReset(`/${iso}/tests/srs`)}}
                >{$_('levelPage.testsSentences')}
                </a>
              </li>
            {/if}
            {#if can.testsAudioSentences}
              <li>
                <a
                  class:-active={menuGroups[2]==='tests' && menuGroups[3] === 'sentences'}
                  href={`/${iso}/tests/sentences`}
                  on:click|preventDefault={() => {navigateWithReset(`/${iso}/tests/sentences`)}}
                >{$_('levelPage.testsAudioSentences')}</a>
              </li>
            {/if}
          {/if}
          <li>
            <a
              class:-active={menuGroups[2]==='tests' && menuGroups[3] === 'customWords'}
              href={`/${iso}/tests/customWords`}
              on:click|preventDefault={() => {navigateWithReset(`/${iso}/tests/customWords`)}}
            >{$_('levelPage.myWords')}</a>
          </li>
          <li>
            <a
              class:-active={menuGroups[2]==='tests' && menuGroups[3] === 'customSentences'}
              href={`/${iso}/tests/customSentences`}
              on:click|preventDefault={() => {navigateWithReset(`/${iso}/tests/customSentences`)}}
            >{$_('levelPage.mySentences')}</a>
          </li>
        </ul>
      </li>
      {#if can.listenWords || can.listenSentences}
        <li class='manu-item -hide'>
          <span
            class='fake-link -hide'
            class:-active={locationGroup==='listen'}
            role='button'
            tabindex='0'
            on:click={viewSubpages}
            on:keypress={() => {}}
          >
            <Icon icon='Headphones' />{$_('levelPage.listen')}
            <span class='arrow -closed' />
          </span>
          <ul class='dropdown-menu'>
            {#if can.listenWords}
              <li>
                <a class:-active={menuGroups[2]==='listen-words'} href={`/${iso}/listen-words`}>{$_('levelPage.listenWords')}</a
                ></li>
            {/if}
            {#if can.listenSentences}
              <li>
                <a
                  class:-active={menuGroups[2]==='listen-sentences'}
                  href={`/${iso}/listen-sentences`}
                >{$_('levelPage.listenSentences')}</a>
              </li>
            {/if}
          </ul>
        </li>
      {/if}
    {/if}
    {#if can.native}
      <li class='manu-item -hide'>
        <a class:-active={menuGroups[2]==='task' && menuGroups[4] === 'find'} href={`/${iso}/task/all/find`}>
          <Icon icon='Notepad' />{$_('levelPage.help')}</a>
      </li>
    {:else}
      <li class='manu-item -hide'>
        <span
          class='fake-link -hide'
          class:-active={locationGroup==='exercise'}
          role='button'
          tabindex='0'
          on:click={viewSubpages}
          on:keypress={() => {}}
        >
          <Icon icon='Notepad' />{$_('levelPage.exercises')}
          <span class='arrow -closed' />
        </span>
        <ul class='dropdown-menu'>
          <li>
            <a
              class:-active={menuGroups[2]==='task' && menuGroups[3] === 'translate' && (menuGroups[4] === 'select' || menuGroups[4] === 'create')}
              href={`/${iso}/task/translate/select/`}
            >{$_('tasks.translate')}</a>
          </li>
          <li>
            <a
              class:-active={menuGroups[2]==='task' && menuGroups[3] === 'read' && (menuGroups[4] === 'select' || menuGroups[4] === 'create')}
              href={`/${iso}/task/read/select`}
            >{$_('tasks.read')}</a>
          </li>
          <li>
            <a
              class:-active={menuGroups[2]==='task' && menuGroups[3] === 'listen' && (menuGroups[4] === 'select' || menuGroups[4] === 'create')}
              href={`/${iso}/task/listen/select`}
            >{$_('tasks.listen')}</a>
          </li>
          <li>
            <a
              class:-active={menuGroups[2]==='task' && menuGroups[3] === 'story' && menuGroups[4] === 'create'}
              href={`/${iso}/task/story/create`}
            >{$_('tasks.story')}</a>
          </li>
          <li>
            <a
              class:-active={menuGroups[2]==='task' && (menuGroups[4] === 'find' || menuGroups[4] === 'correct')}
              href={`/${iso}/task/all/find`}
            >{$_('levelPage.help')}</a>
          </li>
        </ul>
      </li>
    {/if}
    {#if can.read}
      <li>
        <a class:-active={locationGroup==='reading'} href={`/${iso}/reading-learn`}>
          <Icon icon='BookOpenText' />{$_('levelPage.learnToRead')}</a>
      </li>
    {/if}
    {#if can.chat}
      <li>
        <a class:-active={locationGroup==='chat'} href={`/${iso}/chat`}>
          <Icon icon='ChatsCircle' />{$_('levelPage.chat')}
          {#if countNew}<span class='_badge'>{countNew}</span>{/if}
        </a>
      </li>
    {/if}
    <!--
      <li>
        <a class:-active={locationGroup==='words-forms'} href={`/${iso}/words-forms`}>
        <Icon icon='Files' />{$_('levelPage.wordForms')}</a>
      </li>
     -->
    <li>
      <a class:-active={locationGroup==='goals'} href={`/${iso}/goals`}>
        <Icon icon='ChartLineUp' />{$_('levelPage.goals')}
      </a>
    </li>
    <!--
     <li>
        <a class:-active={locationGroup==='guides'} href={`/${iso}/guides`}>
          <Icon icon='Info' />{$_('levelPage.guides')}
        </a>
      </li>
     -->
    <li><a class:-active={locationGroup==='forum'} href={`/${iso}/forum`}>
      <Icon icon='UsersThree' />{$_('menu.forum')}</a>
    </li>
    <li>
      <a class:-active={locationGroup==='search-users'} href='/search-users'>
        <Icon icon='MagnifyingGlass' />
        {$_('menu.searchUsers')}</a>
    </li>
    <li>
      <a class:-active={locationGroup==='missions'} href='/missions'>
        <Icon icon='CheckSquareOffset' />{$_('menu.missions')}</a>
    </li>
    {#if $userStorredConfig.is_admin > 0}
      <li>
        <a class:-active={locationGroup==='design'} href='/design'>
          <Icon icon='Cube' />
          Design
        </a>
      </li>
    {/if}
    {#if $userStorredConfig.is_admin > 0}
      <li>
        <span
          class='fake-link -hide'
          class:-active={locationGroup==='stats'}
          role='button'
          tabindex='0'
          on:click={viewSubpages}
          on:keypress={() => {}}
        >
          <Icon icon='ChartBar' />
          {$_('profile.stats')}
          <span class='arrow -closed' />
        </span>
        <ul class='dropdown-menu'>
          <li>
            <a class:-active={menuGroups[1]==='rang-list'} href='/rang-list'>
              <span class='title'>{$_('menu.statsAll')}</span>
            </a>
          </li>
          <li>
            <a class:-active={menuGroups[2]==='stats'} href='/stats'>
              <span class='title'>{$_('profile.stats')}</span>
            </a>
          </li>
          <li>
            <a class:-active={menuGroups[2]==='vocabulary'} href={`/${iso}/vocabulary`}>
              <span class='title'>{$_('profile.vocabulary')}</span>
            </a>
          </li>
          <li>
            <a class:-active={menuGroups[2]==='stats-leveled'} href={`/${iso}/stats-leveled`}>
              <span class='title'>{$_('stats.leveled')}</span>
            </a>
          </li>
        </ul>
      </li>
    {/if}
    <li class='manu-item -hide'>
      <span
        class='fake-link -hide'
        class:-active={locationGroup==='tools'}
        role='button'
        tabindex='0'
        on:click={viewSubpages}
        on:keypress={() => {}}
      ><Icon icon='Wrench' />{$_('levelPage.tools')}
        <span class='arrow -closed' />
      </span>
      <ul class='dropdown-menu'>
        <li>
          <a class='link' href='#tools' on:click|preventDefault={() => openTools('wordInfo')}>
            <span class='title'>{$_('tools.wordInfo')}</span>
          </a>
        </li>

        <li>
          <a class='link' href='#tools' on:click|preventDefault={() => openTools('addWord')}>
            <span class='title'>Add word</span>
          </a>
        </li>

        <li>
          <a class='link' href='#tools' on:click|preventDefault={() => openTools('addWord','sentence')}>
            <span class='title'>Add sentence</span>
          </a>
        </li>

        <li>
          <a class='link' href='#tools' on:click|preventDefault={() => openTools('addNotes')}>
            <span class='title'>{$_('notes.notes')}</span>
          </a>
        </li>
        {#if $userStorredConfig.is_admin > 0}
          <li>
            <a class='link' href={`/${iso}/word-properties`}>
              <span class='title'>Word properties</span>
            </a>
          </li>
        {/if}
      </ul>
    </li>
    {#if $userStorredConfig.is_admin > 0}
      <li class='manu-item -hide'>
          <span
            class='fake-link -hide'
            class:-active={locationGroup==='advanced'}
            role='button'
            tabindex='0'
            on:click={viewSubpages}
            on:keypress={() => {}}
          >
            <Icon icon='Crown' />Advanced tools
            <span class='arrow -closed' />
          </span>
        <ul class='dropdown-menu'>
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='bulk-add-words'}
              href={`/${iso}/advanced/bulk-add-words`}
            >
              <span class='title'>Bulk add words</span>
            </a>
          </li>
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='language-scripts'}
              href={`/${iso}/advanced/language-scripts`}
            >
              <span class='title'>Language scripts</span>
            </a>
          </li>
          <!--
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='full-lesson-view'}
              href={`/${iso}/advanced/full-lesson-view`}
            >
              <span class='title'>Full lesson view</span>
            </a>
          </li>
          -->
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='vocabulary-compare'}
              href={`/${iso}/advanced/vocabulary-compare`}
            >
              <span class='title'>Compare languages</span>
            </a>
          </li>
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='word-props-patterns'}
              href={`/${iso}/advanced/word-props-patterns`}
            >
              <span class='title'>Words patterns</span>
            </a>
          </li>
          <li>
            <a
              class='link'
              class:-active={menuGroups[3]==='import'}
              href={`/${iso}/advanced/import`}
            >
              <span class='title'>Import custom text</span>
            </a>
        </ul>
      </li>
    {/if}
  </ul>
{/key}
<style lang='scss'>
  .vertical-menu {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
  }

  .vertical-menu > li,
  .dropdown-menu > li {
    position: relative;
    margin: 0;
    padding: 0;
  }

  /*link with items open */
  li:has(.dropdown-menu):not(:has(.-hide)) {
    > .fake-link {
      position: relative;
      z-index: 10;
      background: var(--main-background);
      border-radius: 0.8rem;
      box-shadow: var(--Shadow-Z);
    }
  }

  .dropdown-menu {
    display: block;
    background-color: var(--main-background);
    border-radius: 0.8rem;
  }

  .manu-item {
    background-color: var(--main-background);
    border-radius: 0.8rem;

    &.-hide {
      background-color: inherit;
      border-radius: 0.8rem;
    }
  }

  .fake-link, a {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    padding: 0.8rem 1.6rem;
    font: var(--Semibold-400);
    color: var(--text-primary-color);
    border: solid 1px transparent;
    border-radius: 0.8rem;
    cursor: pointer;

    &.-active,
    &:hover {
      color: var(--Primary-Medium);
      background-color: var(--form-background);
      /* Shadow 100 */
      box-shadow: var(--Shadow-Z);
    }

  }

  .fake-link > .arrow {
    display: flex;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: auto;
    background: url(/images/arrowup.svg) no-repeat 100% 60%;
    background-size: 1.6rem 1.6rem;
  }

  li:has(.-hide) {
    > .dropdown-menu {
      display: none;
    }

    .fake-link > .arrow {
      background-image: url(/images/arrowdown.svg);
    }

    &.-hide {
      box-shadow: unset;
    }
  }

  .dropdown-menu > li {
    padding: 0.8rem 1.6rem 0.8rem 5.6rem;

    > a {
      padding: 0;
      border: none;
      box-shadow: none;

      &.-active,
      &:hover {
        background-color: inherit;
      }
    }
  }
</style>
