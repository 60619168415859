<script lang='ts'>
  const path = window.location.href
  let show = false
  if (path.includes('/tests')) {
    show = true
  }
</script>
{#if show}
  <div class='alert-box'>
    <h2>No cheating!</h2>
    We advice you to not to use any option under tools while solving tests.
  </div>
{/if}
<style lang='scss'>
  .alert-box {
    padding: 2rem;
    text-align: center;
    color: var(--inverted-text-color);
    background-color: var(--color-error);
  }
</style>
