<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { LockedMission, Mission, MissionGroups, MissionUser } from '@/definitions/langoid'
  import { findMissionTabValue, userStorredConfig } from '@/store'

  export let id = 0

  let missions: Mission[] = []
  /* eslint-disable sort-keys */
  let missionGroups: MissionGroups = {
    regular: [],
    additional: [],
    expert: [],
    moderator: [],
    premium: []
  }
  const groupKeys = [
    'regular',
    'additional',
    'expert',
    'moderator',
    'premium'] as const
  let active: string = 'regular'
  const locked: LockedMission = {} as LockedMission
  let activeMission: Mission | undefined
  let user: MissionUser = {} as MissionUser,
    complete: Record<number, string> = {}
  const iso = getIsoFromId($userStorredConfig.id_lang_learning)

  loadMissionsPage()

  async function loadMissionsPage () {
    const data = await fetchData('main/missions', {})
    user = data.user
    missions = data.missions || [] as Mission[]
    complete = data.complete
    let markedEnabled = false
    missions.map((mission: Mission) => {
      if (mission.mission_group === 'regular' && !complete[mission.id]) {
        if (!markedEnabled) {
          markedEnabled = true
          mission.disabled = false
        } else {
          mission.disabled = true
        }
      }

      return mission
    })
    locked.premium = user.is_premium === 0
    locked.moderator = user.is_admin === 0
    /* eslint-disable sort-keys */
    missionGroups = {
      regular: data.missions.filter((mission: Mission) => mission.mission_group === 'regular'),
      additional: data.missions.filter((mission: Mission) => mission.mission_group === 'additional'),
      expert: data.missions.filter((mission: Mission) => mission.mission_group === 'expert'),
      moderator: data.missions.filter((mission: Mission) => mission.mission_group === 'moderator'),
      premium: data.missions.filter((mission: Mission) => mission.mission_group === 'premium')
    }
    /* eslint-enable sort-keys */
    if (id > 0) {
      showMission(id)
    }
  }

  const isAllowed = (group: string, user: MissionUser): boolean => {
    if (group === 'regular') return true
    if (group === 'expert') return true
    if (group === 'additional') return true
    if (group === 'premium' && user.is_premium) return true
    if (group === 'moderator' && user.is_admin) return true
    return false
  }

  const showMission = async (id: number) => {
    activeMission = missions.find(mission => mission.id === id)
    const data = await fetchData('main/missionCheck', { id })
    if (activeMission) {
      activeMission.details = data
      activeMission = activeMission
    }
  }
  const completeMission = async (mission: Mission) => {
    const id = mission.id
    if (!id) {
      return
    }
    const data = await fetchData('main/missionComplete', { id })
    notifier.success($_('missions.messageCompleted', {
      values: { points: activeMission?.points || 0 }
    }))
    activeMission = undefined
    complete[id] = data.completedAt
    loadMissionsPage()
    userStorredConfig.setKeys({ level: data.level, points: data.points })
    navigate('/missions')
  }

  let missionsLength: number
  $: ({ missions: missionsLength } = $userStorredConfig)

  const saveTab = (missionGroup: string): void => {
    active = missionGroup
    findMissionTabValue.set(active)
  }

  /*  const selectChange = (e: Event): void => {
      const selectedValue = (e.currentTarget as HTMLSelectElement).value
      saveTab(selectedValue)
    }*/

  const unsubscribe = findMissionTabValue.subscribe(tab => {
    active = tab
  })

  onDestroy(() => {unsubscribe()})
</script>
<div class='mission-page-wrapper'>
  {#if missionsLength === 0}
    <p>{$_('missions.intro')}</p>
  {/if}
  {#if activeMission}
    <div class='_vertical-small'>
      <Breadcrumb
        breads={[
        {mobileUrl: '/missions', text: '', url: '/'},
        {text:$_('menu.missions'), url:'/missions'},
        {text: $_('mission.' + activeMission.slug)}
        ]}
      >
        <h2>{$_('menu.missions')}</h2>
        <div slot='pageHelperIcons'>
          <ReportButton category='mission' kebabItem rel={activeMission.id} />
        </div>
      </Breadcrumb>
      <div class='mission-statement _gap24'>
        <div class='mission-heading'>
          <h2>{$_('mission.' + activeMission.slug)}</h2>
          {#if complete[activeMission.id]}
            <div class='status'>
              <Icon icon='CheckCircle' />
            </div>
          {/if}
        </div>
        <div class='instruction'>{activeMission.description}</div>
        {#if complete[activeMission.id]}
          <div class='complete'>
            <h3>{$_('missions.completed')}</h3>
          </div>
        {:else if activeMission.details}
          <div class='claim-reward'>
            <div class='mission-points'>
              <Icon icon='ShieldStar' />
              <div class='points'>{"+ " + activeMission.points || ''}</div>
            </div>
            {#if activeMission.details.can === true}
              <button
                type='button'
                on:click={() => activeMission ? completeMission(activeMission) : ''}
              >{$_('missions.claimReward')}</button>
            {:else}
              <button disabled type='button'>{$_('missions.claimReward')}</button>
            {/if}
          </div>
        {/if}
        {#if activeMission.desktop}
          {#if activeMission.desktop.indexOf('.gif') > 0}
            <img class='gif' alt='' src={activeMission.desktop} />
          {:else}
            <video class='gif' autoplay loop muted playsinline>
              <source src={activeMission.desktop} type='video/mp4' />
            </video>
          {/if}
        {/if}
        <div class='mobile'>
          {#if activeMission.mobile}
            {#if activeMission.mobile.indexOf('.gif') > 0}
              <img class='gif' alt='' src={activeMission.mobile} />
            {:else}
              <video class='gif' autoplay loop muted playsinline>
                <source src={activeMission.mobile} type='video/mp4' />
              </video>
            {/if}
          {/if}
        </div>
      </div>
    </div>
  {/if}
  <div class='missions-groups _vertical-small' class:-hideTabs={activeMission}>
    <Breadcrumb
      breads={[
      {mobileUrl: `/${iso}`, text: '', url: `/${iso}`},
      {text: $_('menu.missions'), url: '/'}
    ]}
    >
      <h2>{$_('menu.missions')}</h2>
      <!--  Add code bellow if you want Missions group in select list form for mobile resolution -->
      <!--  <select slot='filters' class='_tabs-wrapper _mobileOnly -missions' on:change={selectChange}
              bind:value={active}>
              {#each groupKeys as missionGroup}
                {#if missionGroups[missionGroup].length > 0 && isAllowed(missionGroup, user)}
                  <option value={missionGroup}>{$_('mission.' + missionGroup)}</option>
                {/if}
              {/each}
            </select>
      -->
      <div slot='pageHelperIcons'>
        <PageInfo kebabItem pageName='missions' />
      </div>
    </Breadcrumb>
    {#each groupKeys as missionGroup}
      <div class='tab-item-list _gap24' class:-active={active === missionGroup}>
        <div class='_tabs-wrapper'> <!-- Add this class _desktopOnly if you want Missions group in select list form for mobile resolution -->
          {#each groupKeys as missionGroup}
            {#if missionGroups[missionGroup].length > 0 && isAllowed(missionGroup, user)}
              <button
                class:_transparent-gray={active !== missionGroup}
                tabindex='0'
                type='button'
                on:click={() => saveTab(missionGroup)}
                on:keypress={() => {}}
              >
                {$_('mission.' + missionGroup)}
              </button>
            {/if}
          {/each}
        </div>
        {#each missionGroups[missionGroup] as mission}
          <div class='individual-mission'>
            <div class='mission-points -small'>
              <Icon icon='ShieldStar' size={16} />
              <div class='points'>{"+ " + mission.points || ''}</div>
            </div>
            <div class='missionGoal' class:-complete={complete[mission.id]} class:-disabled={mission.disabled} role='tabpanel'>
              {#if mission.disabled}
                {$_('mission.' + mission.slug)}
              {:else if complete[mission.id]}
                <FakeButton onClick={() => navigate('/missions/' + mission.id)}>{$_('mission.' + mission.slug)}</FakeButton>
              {:else}
                <FakeButton onClick={() => navigate('/missions/' + mission.id)}>{$_('mission.' + mission.slug)}</FakeButton>
              {/if}
            </div>
            {#if complete[mission.id]}
              <div class='status -complete'>
                <Icon icon='CheckCircle' weight='fill' />
              </div>
            {/if}
          </div>
          <hr />
        {/each}
      </div>
    {/each}
  </div>
</div>

<style lang='scss'>
  .mission-statement {
    > .instruction {
      font: var(--Regular-400);
      color: var(--text-primary-color);

      &:empty {
        display: none;
      }
    }

    > .complete {
      > h3 {
        color: var(--Primary-Medium);
      }
    }

    > .gif,
    > .mobile > .gif {
      width: 100%;
      max-width: 100%;
      border: 0.1rem solid var(--Gray-Medium);
    }

    > .mobile {
      display: none;
    }
  }

  .mission-heading {
    display: flex;
    justify-content: space-between;

    > .status {
      height: 2.4rem;
      margin-left: auto;
      color: var(--Primary-Medium);
    }
  }

  .claim-reward {
    display: flex;
    gap: 1.6rem;
  }

  .missions-groups {
    padding: 1.6rem;

    &.-hideTabs {
      display: none;
    }
  }

  .tab-item-list {
    display: none;

    &.-active {
      display: flex;
      gap: 1.6rem; // overwrite gap: 2.4rem

      > hr:last-child {
        display: none;
      }
    }
  }

  ._tabs-wrapper {
    margin-bottom: 2.4rem;
  }

  .individual-mission {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    > .missionGoal {
      font: var(--Regular-400);
      color: var(--text-primary-color);
      cursor: pointer;

      > :global(.fake-button) {
        width: unset;

        &:hover {
          color: var(--Accent-Medium);
        }
      }

      &.-complete {
        text-decoration: line-through;
        cursor: auto;

        &:hover {
          color: var(--text-primary-color);
          background: transparent;
        }
      }

      &.-disabled {
        font-weight: lighter;
        font-size: 1.4rem;
        color: var(--text-primary-color);
        background: transparent;
        border: 0;
        box-shadow: none;
        cursor: auto;
      }
    }

    > .status {
      height: 2.4rem;
      margin-left: auto;
      color: var(--Primary-Medium);
    }
  }

  .mission-points {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    width: fit-content;
    padding: 0.8rem 1.2rem 0.8rem 0.8rem;
    font: var(--Semibold-400);
    color: var(--accent-text);
    background-color: var(--accent-background);
    border-radius: 24px;

    &.-small {
      gap: 0.2rem;
      padding: 0.3rem 0.6rem 0.3rem 0.4rem;
      font: var(--Semibold-200);
    }

    > .points {
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    .missions-groups {
      padding: 0;
    }

    ._tabs-wrapper {
      margin-bottom: 0;
    }

    .mission-statement {
      padding: 0;
      background: inherit;
      border: none;
    }

    .tab-item-list {
      &.-active {
        padding: 0;
        background: inherit;
        border: none;
      }
    }

    .mission-statement {
      > .gif {
        display: none;
      }

      > .mobile {
        display: block;
      }
    }
  }
</style>
