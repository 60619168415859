<script lang='ts'>
  // Used for testing different design tokens

  import { LANGUAGE } from '@/config/constants'
  import { languages } from '@/config/languages'

  import { props } from '@/helpers/designProps'
  import { posphorIcons } from '@/helpers/icons'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import AudioRecorder from '@/components/audio/AudioRecorder.svelte'
  import ChatMessage from '@/components/chat/ChatMessage.svelte'
  import FlagsList from '@/components/forum/FlagsList.svelte'
  import ReplyKebab from '@/components/forum/ReplyKebab.svelte'
  import { flags } from '@/components/icons/CountryFlag.svelte'
  // eslint-disable-next-line no-duplicate-imports
  import CountryFlag from '@/components/icons/CountryFlag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import ExampleSentences from '@/components/lesson/ExampleSentences.svelte'
  import Sentence from '@/components/lesson/Sentence.svelte'
  import Avatar from '@/components/social/Avatar.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { _ } from '@/libs/i18n'

  const audioSrc = 'https://cdn.langoid.com/a/sentence-11849-2-202104251607.wav'

  function listCSSVariables () {
    const styles = getComputedStyle(document.documentElement)
    const cssVars = []
    for (let i = 0; i < styles.length; i++) {
      const prop = styles[i]
      if (prop.startsWith('--')) {
        cssVars.push(prop)
      }
    }
    const fontRegex = /--(Regular|Medium|Semibold)/
    const colorRegex = /-(Medium|Light|Dark|Darker|Lighter|Lightest)$/
    const fonts = cssVars.filter((v) => fontRegex.test(v))
    let rest = cssVars.filter((v) => !fontRegex.test(v))
    const colors = rest.filter((v) => colorRegex.test(v))
    rest = rest.filter((v) => !colorRegex.test(v))
    return { colors, fonts, rest }
  }

  const cssVars = listCSSVariables()

  /* eslint-disable */
  function escapeHTML (html: string) {
    const div = document.createElement('div')
    div.textContent = html
    return div.innerHTML
  }

  document.body.addEventListener('click', function(event) {
    const dataCodeElement = (event.target as HTMLElement).closest('[data-code]')

    if (dataCodeElement) {
      const clone: HTMLElement = dataCodeElement.cloneNode(true) as HTMLElement
      clone.querySelectorAll('pre').forEach(pre => pre.remove())

      const html = clone.innerHTML
      const codeElement = document.createElement('pre')
      codeElement.textContent = html

      if (!dataCodeElement.querySelector('pre')) {
        dataCodeElement.insertAdjacentElement('beforeend', codeElement)
      }
    }
  })
  /* eslint-enable */
</script>

<Breadcrumb breads={[{text:'',url:'/'},{text:'Design', url:'design'}]}>
  <h2 class='heading-mobile'>{$_('listenPage.listenWords')}</h2>
</Breadcrumb>
<Accordion>
  <h2 slot='header'>Icons</h2>
  <div slot='content'>
    {#each ['inherit', 'var(--Accent-Medium)', 'var(--Primary-Medium)'] as color}
      <div style:color>
        {#each Object.keys(posphorIcons) as icon}
          <span title={icon}><Icon {icon} /></span>
        {/each}
      </div>
    {/each}
    <h3>Flags</h3>
    <br />
    <hr />
    <br />
    <div class='_horizontal'>
      {#each Object.keys(flags) as icon}
        <div>
          <CountryFlag country={icon} />
          {icon}
        </div>
      {/each}
    </div>
  </div>
</Accordion>
<Accordion>
  <h2 slot='header'>Colors</h2>
  <div slot='content' class='box-list'>
    {#each cssVars.colors as color}
      <div class='small-box' style:color={`var(${color})`}>
        {color}
        <div class='bg' style:background={`var(${color})`} />
      </div>
    {/each}
  </div>
</Accordion>
<Accordion>
  <h2 slot='header'>Typography</h2>
  <div slot='content'>

    <h3>Defined font styles</h3>
    {#each cssVars.fonts as font}
      <div class='font-box'>
        <div>{font}</div>
        <p style:font={`var(${font})`}>Lorem ipsum dolor sit amet, <br />consectetur adipisicing elit. <br />Aperiam culpa deleniti doloremque </p>
      </div>
    {/each}
    <h3>Tags</h3>
    {#each ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as element}
      <div class='font-box'>
        <div>{element}</div>
        <svelte:element this={element}>Lorem ipsum dolor sit amet, <br />consectetur adipisicing elit. <br />Aperiam culpa deleniti doloremque</svelte:element>
      </div>
    {/each}
    <h3>Special classes</h3>
    {#each ['text1', 'text2', 'text3', 'text4', 'text5', 'text6'] as textClass}
      <div class='font-box'>
        <div>{textClass}</div>
        <div class={textClass}>Lorem ipsum dolor sit amet, <br />consectetur adipisicing elit. <br />Aperiam culpa deleniti doloremque</div>
      </div>
    {/each}
  </div>
</Accordion>
<Accordion show>
  <h2 slot='header'>Mix</h2>
  <div slot='content' class='_vertical'>
    <h3>Language chooser</h3>
    <p>Without placeholder</p>
    <LanguageSelect selectedLanguage={LANGUAGE.ENGLISH} />
    <p>With placeholder</p>
    <LanguageSelect placeholder='Choose language' selectedLanguage={LANGUAGE.ENGLISH} />

    <h3>Flags list</h3>
    <div style='display:flex; flex-wrap: wrap;gap:8rem'>

      <FlagsList active={78} languages={[78]} />
      <FlagsList active={78} languages={[78,6]} />
      <FlagsList {...props.flagsList} />
      <FlagsList active={78} languages={[78,6,4,3,1]} />
      <FlagsList active={78} languages={[78,6,4,3,1,2,7,8,9,10,11]} />

      <FlagsList active={3} languages={[3,6,4,3,1,2,7,8,9,10,11,12,13,14,15,16,17,78]} />
      <FlagsList active={3} languages={languages.map(el => el.id)} />

    </div>

    <h3>Kebab</h3>
    <div>
      <ReplyKebab {...props.replyKebab} />
    </div>

    <h3>Audio recoreder</h3>
    <div>
      <AudioRecorder />
    </div>
    <div>
      <AudioRecorder>
        <span slot='start'>{$_('recording.start')}</span>
        <span slot='stop'>{$_('recording.stop')}</span>
      </AudioRecorder>
    </div>
    <h3>Audio player</h3>
    <div class='audio-player'>
      <AudioPlayer src={audioSrc} variant='mini' />
      <span><b>-mini</b> (desktop 28/16, mobile 40/24)</span>
    </div>
    <div class='audio-player'>
      <AudioPlayer src={audioSrc} />
      <span><b>-normal</b> (desktop 40/24, mobile 40/24)</span>
    </div>
    <div class='audio-player'>
      <AudioPlayer src={audioSrc} variant='middle' />
      <span><b>-middle</b> (desktop 40/24, mobile 48/24)</span>
    </div>
    <div class='audio-player'>
      <AudioPlayer src={audioSrc} variant='big' />
      <span><b>-big</b> (desktop 64/40, mobile 80/48)</span>
    </div>
    <div class='audio-player'>
      <AudioPlayer src={audioSrc} variant='progress' />
      <span><b>-progress</b></span>
    </div>

    <h3>Sentence</h3>
    <Sentence {...props.sentence} />

    <h3>Example sentences</h3>
    <ExampleSentences {...props.exampleSentences} />

    <h3>Chat message</h3>
    <div>
      <h4>Corrected</h4>
      <ChatMessage {...props.chatMessage.corrected} />
      <h4>Ask for explanation</h4>
      <ChatMessage {...props.chatMessage.askForExplanation} />
      <h4>With link and numbers</h4>
      <ChatMessage {...props.chatMessage.withLink} />
      <h4>Bookmarked, liked</h4>
      <ChatMessage {...props.chatMessage.bookmark} />
    </div>
  </div>
</Accordion>
<Accordion show>
  <h2 slot='header'>Forms</h2>
  <div slot='content' class='_vertical'>

    <h3>Buttons</h3>
    <div class='buttons-list'>
      <p data-code='true'>
        <button type='button'>Normal</button>
      </p>
      <p data-code='true'>
        <button class='_primary-btn' type='button'>Button</button>
      </p>
      <p data-code='true'>
        <button class='_cta-btn' type='button'>Cta</button>
      </p>
      <p data-code='true'>
        <button class='_warning-btn' type='button'>Warning</button>
      </p>
      <p data-code='true'>
        <button class='_success-btn' type='button'>Success</button>
      </p>
      <p data-code='true'>
        <button class='_transparent-blue' type='button'>Transparent</button>
      </p>
      <p data-code='true'>
        <button class='_secondary-btn' type='button'>Blue border</button>
      </p>
      <p data-code='true'>
        <button class='_tertiary-btn' type='button'>Gray border</button>
      </p>
      <p data-code='true'>
        <button class='_transparent-gray' type='button'>Inactive tab</button>
      </p>
      <p data-code='true'>
        <button class='_maxwidth-btn' type='button'>Max width</button>
      </p>
    </div>
  </div>
</Accordion>

<Accordion show>
  <h2 slot='header'>Forms</h2>
  <div slot='content' class='_vertical'>

    <h3>Icons</h3>
    <FakeButton>
      <div class='icon-wrapper'>
        <span><Icon icon='ArrowLeft' weight='regular' /></span><span>without class, no padding (desktop 24, mobile 24)</span>
      </div>
    </FakeButton>
    <FakeButton>
      <div class='icon-wrapper'>
        <span class='_icon'><Icon icon='ArrowLeft' weight='regular' /></span><span><b>_icon</b> (desktop 40/24, mobile 40/24)</span>
      </div>
    </FakeButton>
    <FakeButton>
      <div class='icon-wrapper'>
        <span class='_icon -noBorder'><Icon icon='ArrowLeft' weight='regular' /></span><span><b>_icon -noBorder</b> (desktop 40/24, mobile 40/24)</span>
      </div>
    </FakeButton>
    <FakeButton>
      <div class='icon-wrapper'>
        <span class='_icon -bigNoBorder'><Icon icon='ArrowLeft' weight='regular' /></span><span><b>_icon -bigNoBorder</b> (desktop 40/24, mobile 48/24)</span>
      </div>
    </FakeButton>
    <FakeButton>
      <div class='icon-wrapper'>
        <span class='_icon -bigBorder'><Icon icon='ArrowLeft' weight='regular' /></span><span><b>_icon -bigBorder</b> (desktop 40/24, mobile 48/24)</span>
      </div>
    </FakeButton>
  </div>
</Accordion>
<Accordion show>
  <h2 slot='header'>Avatars</h2>
  <div slot='content' class='_vertical'>
    <Avatar img='' userName='Hani' />
    <Avatar img='' userName='Bojan' />
    <Avatar img='' userName='Bane' />
    <Avatar img='' userName='Ivana' />
    <Avatar avatarVariant='bigAvatar' img='' userName='Pera' />
    <Avatar avatarVariant='smallAvatar' img='' userName='Pera' />
  </div>
</Accordion>

<style lang='scss'>
  /* stylelint-disable declaration-property-value-allowed-list */
  .box-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .buttons-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .small-box {
    width: 15rem;

    > .bg {
      display: inline-block;
      width: 15rem;
      height: 5rem;
    }
  }

  .font-box {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    padding: 1rem 0;
    border-top: solid var(--Gray-Light) 1px;
  }

  :global(pre) {
    margin: 1rem 0;
    padding: 1rem;
    background: var(--Gray-Lighter);
    border-radius: 0.5rem;
  }

  .audio-player, .icon-wrapper {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
</style>
