<script lang='ts'>
  // todo - logic what to do if he didn't accept cookies
  import { onMount } from 'svelte'
  import { link } from 'svelte-routing'

  import { getCookie, setCookie } from '@/helpers/apiCall'

  let shown = false
  const DAYS_ACCEPT_COOKIE_EXPIRATION = 365
  const acceptCookie = () => {
    setCookie('cookiebar', 'cookie', DAYS_ACCEPT_COOKIE_EXPIRATION)
    shown = false
  }

  onMount(() => {
    if (!getCookie('cookiebar')) {
      shown = true
    }
  })
</script>
<div class='cookie-banner' class:-shown={shown} role='banner'>
  <span>
    We use cookies to help make Langoid better. To use our website, you should accept our
    <a href='/static/cookie-policy' use:link>cookie policy</a>.
  </span>
  <button class='acceptLink' type='button' on:click|preventDefault={acceptCookie}>Accept cookies</button>
</div>

<style lang='scss'>
  .cookie-banner {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200 !important;
    display: none;
    min-width: 100%;
    height: auto;
    min-height: 4rem;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.6);

    &.-shown {
      display: block;
    }
  }
</style>
