<script lang='ts'>
  import { link } from 'svelte-routing'

  import { _ } from '@/helpers/i18n'

  import FooterNotLogged from '@/components/menu/FooterNotLogged.svelte'
  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import YoutubePlayer from '@/components/ui/YoutubePlayer.svelte'

</script>

<div class='home-wrapper'>
  <MenuNotLogged />
  <div class='cover-area -second'>
    <div class='inner-area -second'>
      <!--
      <img class='play-video' alt='Play Video' src='/images/play-video.svg' />
      <h1 class='slogan'>{$_('homeNotLogged.slogan')}</h1> -->
      <h2 class='headingText -thin'>{@html $_('homeNotLogged.learnSerbianOrEnglish')}</h2>
      <YoutubePlayer id='DUoeER79jlg' />
      <a class='primaryButton' href='/register' type='button' use:link>{$_('homeNotLogged.startLearning')}</a>
    </div>
  </div>

  <div class='cover-area -third' style:height='120rem'>
    <div class='inner-area -third'>
      <h2 class='headingText -white'>{$_('homeNotLogged.becomePolyglot')}</h2>
      <div class='two-halfs -third'>
        <div class='heading-picture'>
          <p>{@html $_('homeNotLogged.practiceSentencesConstruct')}</p>
          <picture class='response-image' style:height='70rem'>
            <source height='700' srcset='/images/home/phone.avif' type='image/avif' width='325' />
            <img class='phoneImage' alt='' height='700' src='/images/home/phone.png' width='325' />
          </picture>
        </div>
        <div class='heading-picture -second'>
          <svg class='mask-svg' height='680' viewBox='0 200 787 680' width='460' xmlns='http://www.w3.org/2000/svg'>
            <path
              id='Path_29'
              d='M861.8,1773.486c-10.055,55.043-55.99,98.011-51.173,153.1,5.5,62.944,74.222,96.935,89.714,143.914,20.691,62.747-14.3,113.543-57.821,137.031-63.556,34.3-249.713-32.578-319.035,15.641-93.625,65.122-248.441,87.376-300.464,8.708-2.151-3.254-40.118-49.037-35.8-118.093,3.813-60.952,28.39-104.409,13.186-176.268-11.261-53.222-71.912-82.9-78.426-128.168-5.357-37.229-4.631-88.687,30.123-136.049,41.425-56.455,113.015-60.509,165.334-56.947,76.655,5.219,140.526,40.427,188.828,39.375,55.7-1.214,102.156-34.03,166.342-47.936,34.532-7.482,107.52-12.915,150.159,24.283C859.15,1663.811,868.867,1734.822,861.8,1773.486Z'
              data-name='Path 29'
              fill='#fff'
              transform='translate(-119.528 -1602.378)'
            />
          </svg>
          <picture class='response-image'>
            <source srcset='/images/home/window.avif' type='image/avif' />
            <img class='windowImage' alt='' src='/images/home/window.png' />
          </picture>
          <p>{@html $_('homeNotLogged.homeNotLogged')}<br /><br /></p>
        </div>
      </div>
    </div>
  </div>

  <div class='cover-area -fourth'>
    <div class='inner-area -fourth'>
      <div class='two-halfs -fourth'>
        <div style='width: 4.8rem;height: 4.8rem;'>.</div>
      </div>
    </div>
  </div>

  <div class='cover-area -fifth'>
    <div class='inner-area -fifth'>
      <div class='two-halfs -fifth'>
        <p class='girl-standing'>{@html $_('homeNotLogged.improvePronunciation')}</p>
        <p class='boy-sitting'>{@html $_('homeNotLogged.levelUpYourListening')}</p>
      </div>
      <a class='primaryButton' href='/register' type='button' use:link>{$_('homeNotLogged.startLearning')}</a>
    </div>
  </div>

  <div class='cover-area -sixth'>
    <div class='inner-area -sixth'>
      <div class='two-halfs -sixth'>
        <div style='width: 4.8rem;height: 4.8rem;'>.</div>
      </div>
    </div>
  </div>
  <div class='cover-area -seven'>
    <div class='inner-area -seven'>
      <h2 class='headingText -white'>{@html $_('homeNotLogged.feedbackFromRealPeople')}</h2>
      <div class='two-halfs -seven'>
        <p>{@html $_('homeNotLogged.youWillReceiveCorrections')}</p>
        <div>
          <picture class='response-image'>
            <source srcset='/images/home/sad-phone.avif' type='image/avif' />
            <img class='sadPhone' alt='' src='/images/home/sad-phone.png' />
          </picture>
        </div>
      </div>
      <a class='primaryButton -register' href='/register' type='button' use:link>{$_('homeNotLogged.registerNow')}</a>
    </div>
  </div>
  <FooterNotLogged />
</div>

<style lang='scss'>
  :global(body) {
    position: relative;
    margin: 0 auto;
  }

  p {
    font-size: 2.4rem;
    line-height: 1.4;
  }

  .home-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .inner-area {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;

    > .headingText {
      font-size: 3.2rem;
      font-family: 'Noto Sans', sans-serif;

      &.-thin {
        font-weight: 200;
        line-height: 1.5;
        color: var(--Primary-Medium);
      }

      &.-white {
        font-weight: bold;
        color: var(--inverted-text-color);
      }
    }

    &.-second {
      max-width: 68.4rem;
      text-align: center;

      :global(lite-youtube) {
        max-width: 100%;
        border: 0.1rem solid var(--Gray-Light);
        border-radius: 3rem;
        box-shadow: var(--medium-box-shadow);
      }
    }

    &.-third {
      padding-top: 4rem;

      > .headingText {
        margin: auto;
        text-align: center;
      }
    }

    > .primaryButton {
      display: inline-block;
      width: unset;
      margin: 1.2rem auto;
      padding: 1rem 2rem 1rem 2rem;
      font-weight: bold;
      font-size: 2rem;
      color: var(--inverted-text-color);
      background: var(--Accent-Medium);
      border: 0;
      border-radius: 4rem;

      &.-register {
        margin: 0 0 4.8rem 30%;
        text-align: center;
      }
    }
  }

  .cover-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin: auto;
    background: var(--main-background);
    border: 0;

    &.-third {
      position: relative;
      z-index: 10;
      min-height: 70rem;
      color: var(--inverted-text-color);
      background: var(--Primary-Medium);
    }

    &.-fourth {
      position: relative;
      top: -1rem;
      z-index: 10;
      padding-top: 4rem;
      color: var(--inverted-text-color);
      background: url(/images/home/wave.svg) repeat-x 0 0;
    }

    &.-sixth {
      position: relative;
      top: 1rem;
      z-index: 10;
      padding-top: 4rem;
      color: var(--inverted-text-color);
      background: url(/images/home/wave2.svg) repeat-x 0 0;
    }

    &.-seven {
      color: var(--inverted-text-color);
      background: var(--Primary-Medium);
    }
  }

  .heading-picture {
    > p {
      color: var(--inverted-text-color);
    }

    &.-second {
      position: relative;

      > p {
        position: absolute;
        top: 51.2rem;
        left: -4rem;
        color: var(--inverted-text-color);
      }
    }
  }

  .two-halfs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 4.8rem 0 0 0;

    &.-seven {
      > p {
        margin: 4.8rem 0 9.6rem;
        color: var(--inverted-text-color);
      }
    }
  }

  .response-image {
    > .phoneImage {
      position: absolute;
      top: 34rem;
    }

    > .windowImage {
      position: absolute;
      top: 6rem;
      left: 7rem;
      width: 27rem;
    }

    > .sadPhone {
      position: relative;
      top: -20rem;
      z-index: 20;
    }
  }

  .boy-sitting,
  .girl-standing {
    padding: 7.2rem 0;
  }

  @media (max-width: 1024px) {
    * {
      position: static !important;
    }

    .cover-area {
      &.-third,
      &.-seven {
        margin-right: -1rem;
        margin-left: -1rem;
      }

      &.-fourth {
        margin-right: -2rem;
        margin-left: -2rem;
      }

      &.-sixth {
        visibility: hidden;
      }
    }

    .inner-area {
      &.-third,
      &.-seven {
        padding: 1rem;
      }
    }

    .mask-svg {
      display: none;
    }

    .girl-standing {
      padding: 0;
    }

    .response-image > .sadPhone {
      display: inline-block;
      width: 50%;
      margin: 0 0 4.8rem 25%;
    }

    .two-halfs {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .inner-area {
      > .headingText {
        font-size: 2.4rem;

        &.-thin {
          line-height: 1.2;
        }
      }

      > .primaryButton.-register {
        font-size: 1.6rem;
      }
    }

    .heading-picture > p {
      font-size: 1.8rem;
    }

    .two-halfs {
      &.-fifth,
      &.-seven {
        > p {
          font-size: 1.8rem;
        }
      }

      &.-seven > p {
        margin: 0;
      }
    }
  }
</style>
