import { derived, get, Writable, writable } from 'svelte/store'

import { getIsoFromId } from '@/helpers/apiCall'
import { logCommonLocalError } from '@/helpers/errorsHelper'
import { setupI18n } from '@/helpers/i18n'

import { userStorredConfig } from '@/store'

// todo - instead of making http request to eng messages, can we copy them to src folder on build?

interface Messages {
  [key: string]: string | Messages;
}

interface Params {
  default?: string;
  values?: {
    [key: string]: string | number
  };
}

export const currentLanguage: Writable<string> = writable('')
export const currentMessages: Writable<Messages> = writable({})
export const englishMessages: Writable<Messages> = writable({})

let messages: Messages
let engMessages: Messages
let isDownloading = false

userStorredConfig.subscribe(async (config) => {
  const current = get(currentMessages)
  if (config) {
    const iso = getIsoFromId(config.id_lang_interface, true)
    currentLanguage.set(iso)
    if (Object.keys(current).length === 0) {
      messages = await loadJson(`/i18n/${iso || 'eng'}.json`)
      currentMessages.set(messages as Messages)
    }
  }
})

export function loadJson (url: string, callback = () => {
}) {
  return fetch(url).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      callback()
      return Promise.resolve({ 'a:': 'b' })
    }
  }).catch(error => {
    console.error(`Error when trying to download: "${url}" ${error.message}`)
  })
}

export async function initI18n (lang: string = ''): Promise<void> {
  try {
    if (isDownloading) return
    if (lang === '') {
      lang = await setupI18n() || 'eng'
    }
    document.body.dir = ['arb'].includes(lang) ? 'rtl' : 'ltr'
    // if (lang === get(currentLanguage)) return
    isDownloading = true
    messages = await loadJson(`/i18n/${lang || 'eng'}.json`)
    if (lang === 'eng') {
      engMessages = messages
    } else {
      engMessages = await loadJson('/i18n/eng.json')
    }
    englishMessages.set(engMessages)
    isDownloading = false
    setLanguage(lang)
  } catch (error) {
    console.error('Error during initialization of i18n: ', error)
  }
}

export async function setLanguage (lang: string): Promise<void> {
  if (messages) {
    currentLanguage.set(lang)
    currentMessages.set(messages as Messages)
  }
}

function logMissingTranslation (key: string, lang: string) {
  if (logCommonLocalError(`CONTENT: missing translation lang: ${lang}, key: ${key}`)) {
    console.error(
      `I18N: %cmissing translation for key: %c${key} %c language %c ${lang}`,
      'color:gray',
      'color:red;font-weight:bold;',
      'color:gray',
      'color:red;font-weight:bold;'
    )
  }
}

const getMessage = (keys: string[], messages: Messages): string | Messages => {
  let message: string | Messages = messages
  for (const k of keys) {
    if (typeof message !== 'string') {
      if (typeof message !== 'undefined') {
        message = message[k]
      }
    } else {
      return message
    }
  }
  return message
}

export const _ = derived(
  [currentMessages, currentLanguage, englishMessages],
  ([$currentMessages, $currentLanguage, $englishMessages], set) => {
    set((key: string, params: Params = {}): string => {
      if (!Object.keys($currentMessages || []).length) {
        return key
      }

      const keys: string[] = key.split('.')
      let message: string | Messages = getMessage(keys, $currentMessages)

      if (typeof message !== 'string') {
        logMissingTranslation(key, $currentLanguage)
        if (params.default) {
          message = params.default
        } else {
          message = getMessage(keys, $englishMessages)
          message = typeof message === 'string' ? message : key
        }
      }

      if (typeof params.values === 'object') {
        for (const param in params.values) {
          message = message.replace(`{${param}}`, params.values[param].toString())
        }
      }
      return message
    })
  },
  (key: string, params: Params = {} as Params): string => ''
)
