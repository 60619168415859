<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'

  import LanguagePageLink from '@/components/languagePage/LanguagePageLink.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { LanguageStatus } from '@/definitions/langoid'
  import { languagesStore, userStorredConfig } from '@/store'

  export let iso = ''

  let languageStatus: LanguageStatus = $languagesStore[iso]?.status as LanguageStatus
  const unsubscribe = languagesStore.subscribe(value => {
    languageStatus = value[iso]
  })

  let isMyNative = false
  $: if (iso) {
    isMyNative = getIsoFromId($userStorredConfig.id_lang_native) === iso
  }

  onDestroy(() => {unsubscribe()})
</script>
{#if languageStatus?.status}
  <Breadcrumb>
    <h2 class='heading-mobile'>Tasks</h2>
    <div slot='pageHelperIcons'>
      <PageInfo kebabItem pageName='exercises' />
    </div>
  </Breadcrumb>
  <div class='action-tasks _gap24'>
    {#if !isMyNative}
      <LanguagePageLink
        icon='Notepad'
        {languageStatus}
        priv='task.translate'
        title={$_('tasks.translate')}
        url={`/${iso}/task/translate/select`}
      />
      <LanguagePageLink
        icon='Notepad'
        {languageStatus}
        priv='task.read'
        title={$_('tasks.read')}
        url={`/${iso}/task/read/select`}
      />
      <LanguagePageLink
        icon='Notepad'
        {languageStatus}
        priv='task.listen'
        title={$_('tasks.listen')}
        url={`/${iso}/task/listen/select`}
      />
      <LanguagePageLink
        icon='Notepad'
        {languageStatus}
        priv='task.story'
        title={$_('tasks.story')}
        url={`/${iso}/task/story/create`}
      />
    {/if}
    <LanguagePageLink
      icon='Notepad'
      {languageStatus}
      priv='task.translate'
      title={$_('levelPage.help')}
      url={`/${iso}/task/all/find`}
    />
  </div>
{/if}
