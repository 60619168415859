<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { getCookie, getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'

  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { initI18n } from '@/libs/i18n'
  import { userStorredConfig } from '@/store'

  const unsubscribe = userStorredConfig.subscribe(user => {
    if (user.id > 0 && getCookie('jwt')) {
      location.reload()
    }
  })

  let showButtons = false

  window.addEventListener('popstate', () => {
    showButtons = window.location.pathname === '/'
  })

  if (window.location.pathname === '/') {
    showButtons = true
  }

  function home () {
    showButtons = true
    navigate('/')
  }

  function login () {
    showButtons = false
    navigate('/login')
  }

  function register () {
    showButtons = false
    navigate('/register')
  }

  let choosenLocale: number

  onMount(() => {
    choosenLocale = $userStorredConfig.id_lang_interface
  })

  function handleLocaleChange (langId: number) {
    const interfaceLanguageLocale = getIsoFromId(langId)
    choosenLocale = langId

    initI18n(interfaceLanguageLocale)
    userStorredConfig.setKey('id_lang_interface', langId)
  }

  onDestroy(() => {unsubscribe()})
</script>
<div class='home-not-logged-header'>
  <div class='inner-area -first'>
    <FakeButton onClick={home} type='button'>
      <img class='small-logo' alt='Langoid Logo' height='30' src='/images/logo-main.svg' width='110' />
    </FakeButton>
    <div class='headerRight'>
      {#if showButtons}
        <button type='button' on:click={login}>{$_('auth.login')}</button>
        <button type='button' on:click={register}>{$_('auth.register')}</button>
      {/if}
      <LanguageSelect onChange={handleLocaleChange} type='all' variant='menu' bind:selectedLanguage={choosenLocale} />
    </div>
  </div>
</div>
<style lang='scss'>
  .home-not-logged-header {
    width: 100%;
  }

  .inner-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > .headerRight {
      display: flex;
      gap: 1rem;
    }
  }
</style>
