// https://quilljs.com/docs/api/
import QuillBetterTable from 'quill-better-table'
import Delta from 'quill-delta'
import { get } from 'svelte/store'

import { QuillToolbarButton } from '@/helpers/DynamicQuillTools'
import { mainEmojis } from '@/helpers/emojiHelpers'
import { _ } from '@/helpers/i18n'

type QuillEditor = any
type QuillOptions = Record<string, string | object>

// Created to add support for emojis in Quill editor
class EmojifyModule {
  quill: QuillEditor

  constructor (quill: QuillEditor, options: QuillOptions) {
    this.quill = quill
    this.handleTextChange = this.handleTextChange.bind(this)
    this.quill.on('text-change', this.handleTextChange)
  }

  handleTextChange (delta: Delta, oldDelta: Delta, source: string) {
    if (source === 'user') {
      const currentText = this.quill.getText()
      const cursorPosition = this.quill.getSelection(true).index

      const maxEmojiLength = 10
      // Assume emoji code will not be longer than 10 characters.
      const contextString = currentText.slice(Math.max(0, cursorPosition - maxEmojiLength), cursorPosition)

      let emojifiedText = currentText

      if (contextString) {
        if (contextString.indexOf(':') > -1) {
          Object.keys(mainEmojis).forEach(key => {
            const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            const regex = new RegExp(escapedKey)
            if (regex.test(contextString)) {
              emojifiedText = currentText.slice(0, cursorPosition - key.length) +
                mainEmojis[key] + currentText.slice(cursorPosition)
            }
          })
        } else if (contextString.indexOf('<3') >= 0 || // for <3
          contextString.indexOf('))') >= 0 || contextString.indexOf('=)') >= 0 // Russian smileys
        ) {
          ['<3', '))', '=)'].forEach(key => {
            if (contextString.indexOf(key) >= 0) {
              emojifiedText = currentText.slice(0, cursorPosition - key.length) +
                mainEmojis[key] + currentText.slice(cursorPosition)
            }
          })
        }
      }

      if (currentText !== emojifiedText) {
        // Temporarily stop listening to 'text-change' events to avoid an infinite loop
        this.quill.off('text-change', this.handleTextChange)
        // Updates the entire text of the editor
        this.quill.setText(emojifiedText)
        // Restores the cursor position
        this.quill.setSelection(cursorPosition)
        // Resumes listening to 'text-change' events
        this.quill.on('text-change', this.handleTextChange)
      }
    }
  }
}

async function quill (node: HTMLElement, options: QuillOptions) {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike', 'blockquote', { header: 1 }],
    [{ list: 'ordered' }, { list: 'bullet' }, 'link', 'image',
      'video'],
    [{ direction: 'rtl' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }, 'clean']
  ]

  const style = getComputedStyle(document.body)
  import('quill').then(({ default: Quill }) => {
    Quill.register({
      'modules/better-table': QuillBetterTable,
      'modules/emojify': EmojifyModule
    }, true)
    /* eslint-disable sort-keys */
    const quill = new Quill(node, {
      modules: {
        table: false,
        emojify: true,
        'better-table': {
          operationMenu: {
            items: {
              unmergeCells: {
                text: 'Another unmerge cells name'
              }
            },
            color: {
              colors: [
                style.getPropertyValue('--Warning-Lighter'),
                style.getPropertyValue('--Success-Lighter'),
                style.getPropertyValue('--Error-Lighter'),
                style.getPropertyValue('--Accent-Lighter'),
                style.getPropertyValue('--Warning-Lighter'),
                style.getPropertyValue('--Success-Lighter'),
                style.getPropertyValue('--Error-Light'),
                style.getPropertyValue('--Accent-Light'),
                style.getPropertyValue('--Warning-Light'),
                style.getPropertyValue('--Success-Light'),
                style.getPropertyValue('--Error-Medium'),
                style.getPropertyValue('--Primary-Medium'),
                style.getPropertyValue('--Warning-Light'),
                style.getPropertyValue('--Success-Light'),
                style.getPropertyValue('--Error-Dark'),
                'black',
                'yellow',
                'green',
                'red',
                'gray',
                'white'
              ],
              text: 'Background Colors:'
            }
          }
        },
        // keyboard: {
        //   bindings: quillBetterTable.keyboardBindings
        // }
        toolbar: toolbarOptions
      },
      placeholder: get(_)('writeHere'),
      theme: 'snow',
      ...options
    })
    /* eslint-enable sort-keys */

    // const table = quill.getModule('table')
    const tableModule = quill.getModule('better-table')

    // Add a custom Button to the Quill Editor's toolbar:
    const tableButton = new QuillToolbarButton({

      icon: '<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect class="ql-fill" height="2" width="3" x="5" y="5"></rect> <rect class="ql-fill" height="2" width="4" x="9" y="5"></rect> <g class="ql-fill ql-transparent"> <rect height="2" width="3" x="5" y="8"></rect> <rect height="2" width="4" x="9" y="8"></rect> <rect height="2" width="3" x="5" y="11"></rect> <rect height="2" width="4" x="9" y="11"></rect> </g> </svg>'
    })

    tableButton.onClick = (quill) => {
      // table.insertTable(3, 3)
      // @ts-ignore
      tableModule.insertTable(3, 3)
    }
    tableButton.attach(quill)
    const container = node.getElementsByClassName('ql-editor')[0]

    if (document.getElementById('quill-translations') === null) {
      const styles = `<style id='quill-translations'>
      .ql-snow .ql-tooltip[data-mode='video']::before { content: '${get(_)('quill.enterVideo')}' !important; }
      .ql-snow .ql-tooltip[data-mode='formula']::before { content: '${get(_)('quill.enterFormula')}' !important; }
      .ql-snow .ql-tooltip[data-mode='link']::before { content: '${get(_)('quill.enterLink')}' !important; }
      .ql-snow .ql-tooltip.ql-editing a.ql-action::after { content: '${get(_)('save')}' !important; }
      .ql-snow .ql-tooltip a.ql-action::after { content: '${get(_)('edit')}' !important; }
      .ql-snow .ql-tooltip a.ql-remove::before { content: '${get(_)('remove')}' !important; }
      .ql-snow .ql-tooltip::before { content: '${get(_)('quill.visitUrl')}' !important; }
</style>
`

      document.head.insertAdjacentHTML('beforeend', styles)
    }

    quill.on('text-change', (delta: Delta, oldDelta: Delta, source: string) => {
      node.dispatchEvent(
        new CustomEvent('text-change', {
          detail: {
            html: container.innerHTML,
            text: quill.getText()
          }
        })
      )
    })
  })
}

export { quill }
