<script lang='ts'>
  // todo - improve based on https://mockup.langoid.com/read/index.php
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { CharsVariants, LearnChars, LearnReadApiData } from '@/definitions/langoid'

  export let iso: string

  let chars: Record<string, LearnChars[]> = {}
  let variants: CharsVariants[] = []
  let questionsCount: number = -1

  const load = async () => {
    const data: LearnReadApiData = await fetchData('read/load', {})
    chars = data.chars
    variants = data.variants
    questionsCount = data.questionsCount
  }
  load()
</script>

<Breadcrumb breads={[{text:'',url:`/${iso}`}, { text: $_('levelPage.learnToRead')}]}>
  <h2 class='heading-mobile'>{$_('read.title')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='reading' />
  </div>
</Breadcrumb>
<div class='learn-reading _gap24'>
  {#each variants as variant}
    <h2>{$_(variant.explanation)} - {variant.script}</h2>
    <div class='letters-list'>
      {#each chars[variant.script] as letter}
        {#if variant.id_variant === letter.variant}
          <span class='letter'>{letter.content}</span>
        {/if}
      {/each}
    </div>
  {/each}
  {#if questionsCount > 0}
    <a class='button' href='/{iso}/reading-progress' use:link>{$_('read.startLearning')} →</a>
  {:else if questionsCount === 0}
    <p>{$_('read.notAvailable')}</p>
  {/if}
</div>

<style lang='scss'>
  .letters-list > .letter {
    display: inline-block;
    padding: 0.5rem;
  }

  .learn-reading {
    > .button {
      display: inline-block;
      margin-top: 2.8rem;
      padding: 1rem 2rem;
      color: var(--inverted-text-color);
      background-color: var(--Primary-Medium);
      border-radius: 2rem;
      cursor: pointer;
    }
  }
</style>
