<script lang='ts'>
  import { onDestroy, tick } from 'svelte'

  import { EXECUTION_TIME_HACK } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { poses, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { _ } from '@/libs/i18n'
  import { toolsMyLanguage, toolsTranslateToLanguage, userStorredConfig } from '@/store'

  interface AddWordForm {
    content: string,
    definition: string,
    idMyWord: number,
    idTranslatedWord: number, // if word in my language exists in the database
    myLanguage: number,
    note: string,
    pos: number | string,
    translateToLanguage: number,
    translation: string
  }

  type TaggedWords = Record<number, {
    definition: string,
    engDefinition: string,
    id: number,
    pos: number;
    word: string
  }[]>

  export let type: 'word' | 'sentence' = 'word'
  export let rows = 1
  export let toolsWord = ''

  const editing = {
    foreign: false,
    my: false
  }
  const emptyForm = (word: string = ''): AddWordForm => {
    const empty = {
      content: word || '',
      definition: '',
      idMyWord: 0,
      idTranslatedWord: 0,
      myLanguage: 0,
      note: '',
      pos: '',
      translateToLanguage: $toolsTranslateToLanguage,
      translation: ''
    }
    return { ...empty }
  }

  let form: AddWordForm = emptyForm(toolsWord)
  let autocompletedWordsMy = {} as TaggedWords
  let autocompletedWords = {} as TaggedWords
  let knownWords: Record<number, number> = {}
  const handleTranslateToLangChange = (lang: number) => {
    if (lang === form.myLanguage) {
      notifier.error($_('tasksPage.sameLang'))
      return
    }
    toolsTranslateToLanguage.set(toInt(lang))
    editing.foreign = false
  }
  const handleMyLangChange = (lang: number) => {
    if (form.translateToLanguage === lang) {
      notifier.error($_('tasksPage.sameLang'))
      return
    }
    toolsMyLanguage.set(toInt(lang))
    editing.my = false
  }

  const unsubscribe = toolsTranslateToLanguage.subscribe(lang => {
    form.translateToLanguage = lang
  })
  toolsMyLanguage.subscribe(lang => {
    form.myLanguage = lang
  })
  setTimeout(() => {
    if (toInt(form.myLanguage) === 0) {
      form.myLanguage = toInt($userStorredConfig.id_lang_interface)
    }
  }, EXECUTION_TIME_HACK)

  const resetFormButKeepLanguage = () => {
    const empty = emptyForm()
    empty.myLanguage = form.myLanguage
    form = { ...empty }
    autocompletedWords = {}
    autocompletedWordsMy = {}
  }

  const submitForm = async () => {
    await fetchData('learn/addWord', { ...form, type })
    resetFormButKeepLanguage()
    document.getElementById('word-translation')?.focus()
    notifier.success($_('tools.wordAdded'))
  }

  const autocomplete = async () => {
    if (type === 'sentence' || form.translation.length === 0 || form.content.length === 0) {
      return
    }
    autocompletedWords = {}
    autocompletedWordsMy = {}
    const res = await fetchData('learn/autocompleteWord', {
      content: form.content,
      myLanguage: form.myLanguage,
      translateToLanguage: form.translateToLanguage,
      translation: form.translation
    })
    autocompletedWords = res.words
    autocompletedWordsMy = res.wordsMy
    knownWords = res.knownWords
  }

  const selectWord = (id: number, lang: 'my' | 'foreign') => {
    const word = lang === 'my' ? autocompletedWordsMy[id][0] : autocompletedWords[id][0]
    if (lang === 'my') {
      form.translation = word.word
      form.idTranslatedWord = word.id
      form.pos = word.pos.toString()
    } else {
      form.content = word.word
    }
    autocompletedWords = {}
    autocompletedWordsMy = {}
    tick()
  }

  const addToLearningLoop = async (wordId: number) => {
    await fetchData('learn/lessonAddToLearningLoop', { wordId })
    resetFormButKeepLanguage()
  }

  onDestroy(() => {unsubscribe()})
</script>
<form class='add-word-form _form' action='' on:submit|preventDefault={submitForm}>
  <div class='languages-wrapper'>
    <div>{$_('tools.yourLanguage')}
      <br />
      {#key form.myLanguage}
        <LanguageSelect onChange={handleMyLangChange} selectedLanguage={form.myLanguage} />
      {/key}
    </div>
    <div>{$_('tools.foreignLanguage')}
      <br />
      <LanguageSelect onChange={handleTranslateToLangChange} selectedLanguage={form.translateToLanguage} />
    </div>
  </div>
  <div>
    <p>{$_('tools.addWhat')}</p>
    <div class='add-word-type'>
      <label class='custom-radio-circle'>
        <input type='radio' value='word' bind:group={type} />
        <span class='radioIcon'>{$_('tools.word')}</span>
      </label>
      <label class='custom-radio-circle'>
        <input type='radio' value='sentence' bind:group={type} />
        <span class='radioIcon'>{$_('tools.sentence')}</span>
      </label>
    </div>
    {#if type === 'word'}
      <h3>{$_('tools.addWords')}</h3>
    {:else}
      <h3>{$_('tools.addSentences')}</h3>
    {/if}
  </div>
  <div class='languages-list'>
    <p>
      <label for='word-translation'>{$_('tools.inMyLanguage')}</label>
      <textarea
        id='word-translation'
        required
        {rows}
        bind:value={form.translation}
        on:change={() => {
          autocomplete()
          form.idTranslatedWord = 0
        }}
        name='word-translation'
      />
    </p>
    {#if Object.values(autocompletedWordsMy).length > 0}
      <table class='words-table'>
        {#each Object.values(autocompletedWordsMy) as wordWifs}
          {@const word = wordWifs[0]}
          <tr on:click={() => { selectWord(word.id, 'my') }}>
            <td>{word.word}</td>
            <td>{$_('pos.' + word.pos)}</td>
            <td>{word.definition || word.engDefinition}</td>
          </tr>
        {/each}
      </table>
    {/if}
    <p>
      <label for='word-content'>{$_('tools.foreignLanguage')}</label>
      <textarea
        id='word-content'
        required
        {rows}
        on:change={autocomplete}
        bind:value={form.content}
        name='word-content'
      />
    </p>
    {#if Object.values(autocompletedWords).length > 0}
      <table>
        {#each Object.values(autocompletedWords) as wordWifs}
          {@const word = wordWifs[0]}
          <tr>
            <td>
              {#if knownWords[word.id] > 0}
                <span class='known-word'>{$_('chat.alreadyKnown')}</span>
              {:else}
                <button
                  type='button'
                  on:click={() => { addToLearningLoop(word.id) }}
                >{$_('chat.addToLearningLoop')}</button>
              {/if}
            </td>
            <td>{word.word}</td>
            <td>{$_('pos.' + word.pos)}</td>
            <td>{word.definition || word.engDefinition}</td>
          </tr>
        {/each}
      </table>
    {/if}
  </div>
  {#if type === 'word'}
    <p>
      <label for='word-definition'>{$_('tools.definition')}</label>
      <textarea id='word-definition' bind:value={form.definition} name='word-definition' />
    </p>
  {/if}
  <p>
    <label for='word-note'>{$_('tools.note')}</label>
    <textarea id='word-note' bind:value={form.note} name='word-note' />
  </p>
  {#if type === 'word'}
    <p>
      <label for='word-pos'>{$_('tools.pos')}</label>
      <select id='word-pos' bind:value={form.pos} name='word-pos'>
        <option value=''>{$_('tools.choose')}</option>
        {#each poses as pos}
          <option value={pos.id.toString()}>{$_('pos.' + pos.id)}</option>
        {/each}
      </select>
    </p>
  {/if}
  <input type='submit' value={$_('save')} />
</form>
<style lang='scss'>
  .add-word-form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .add-word-type, .languages-wrapper {
    display: flex;
    gap: 1.6rem;
  }

  .languages-list {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .words-table > tr:hover {
    background-color: var(--Gray-Light);
    cursor: pointer;
  }

  @media(max-width: 768px) {
    .languages-wrapper {
      flex-direction: column;
    }
  }
</style>
