<script lang='ts'>

import {languages} from "@/config/languages";

import {fetchData} from "@/helpers/fetchHelpers";

import LanguageSelect from "@/components/ui/LanguageSelect.svelte";
const langs = [86,2,12]
let langNames=['','','']
let results: any
const setLanguage = (lang: number, index: number) => {
  langs[index] = lang
  results = undefined

}
const compareLanguages = async () => {
  const langsFiltered=langs.filter(lang => lang>0)
  const data = await fetchData('main/compareLanguages', {
    languages: langsFiltered
  })
  langNames = [
    languages.find(lang => lang.id === langs[0])?.name,
    languages.find(lang => lang.id === langs[1])?.name,
    languages.find(lang => lang.id === langs[2])?.name
  ]

  results = data
}

const calculateClass = (s01: number, s02: number, s12: number) => {
  const theSame = 100
  const similar = 85
  const littleSimilar = 70
  const soSo = 55
  const classes = ['', '', '']
  if (s01 >= theSame || s02 >= theSame) {
    classes[0] = 'theSame'
  }
  if (s01 >= theSame || s12 >= theSame) {
    classes[1] = 'theSame'
  }
  if (s12 >= theSame || s02 >= theSame) {
    classes[2] = 'theSame'
  }
  if(s01<theSame && s02<theSame && (s01>=similar || s02>=similar)){
    classes[0] = 'similar'
  }
  if( s01<theSame && s12<theSame && (s01>=similar|| s12>=similar)){
    classes[1] = 'similar'
  }
  if(s12<theSame && s02< theSame && (s12>=similar || s02>=similar)){
    classes[2] = 'similar'
  }
  if(s01<similar && s02 < similar && (s01>=littleSimilar  || s02>=littleSimilar)){
    classes[0] = 'littleSimilar'
  }
  if(s01<similar  && s12<similar && (s01>=littleSimilar || s12>=littleSimilar)){
    classes[1] = 'littleSimilar'
  }
  if(s12<similar && s02 < similar && (s12>=littleSimilar || s02>=littleSimilar)){
    classes[2] = 'littleSimilar'
  }
  if(s01<littleSimilar && s02 < littleSimilar && (s01>=soSo || s02>=soSo)){
    classes[0] = 'soSo'
  }
  if(s01<littleSimilar && s12<littleSimilar && (s01>=soSo || s12>=soSo)) {
    classes[1] = 'soSo'
  }
  if(s12<littleSimilar && s02 < littleSimilar && (s12>=soSo || s02>=soSo)){
    classes[2] = 'soSo'
  }

  return classes
}
</script>
<h2>Compare vocabulary of two languages</h2>
<p>Page for comparing languages by their roots</p>

<p>Choose two or three languages to compare</p>
<div class="_horizontal">
  <div>
<LanguageSelect onChange={(lang) => {setLanguage(lang,0);return true}}
selectedLanguage={langs[0]} /></div>
  <div>
<LanguageSelect onChange={(lang) => {setLanguage(lang,1);return true}}
selectedLanguage={langs[1]} />
    </div>
  <div>
<LanguageSelect onChange={(lang) => {setLanguage(lang,2);return true}}
selectedLanguage={langs[2]} />
    </div>
</div>
<input type="button" value="Compare" on:click={compareLanguages} />
{#if results?.words}
<div>
  <h3>Differences</h3>
<table>
  <tr>
    <th>Word</th>
    <th>{langNames[0]}</th>
    <th>{langNames[1]}</th>
    <th>{langNames[2]}</th>
  </tr>
{#each Object.keys(results.words) as index}
  {@const word = results.words[index]}
  {@const classes = calculateClass(word.s01,word.s02,word.s12)}
  <tr class="word-row">
    <td>{word.word}</td>
    <td class={classes[0]}>{word[langs[0]].word}</td>
    <td class={classes[1]}>{word[langs[1]].word}</td>
    <td class={classes[2]}>{word[langs[2]].word}</td>
  </tr>
  {/each}
</table>
</div>
  {/if}

<style lang='scss'>
  .word-row {
    > .theSame {
      color: var(--Base-White);
      background: var(--Green-Dark);
    }
    > .similar {
      background: var(--Green-Medium);
    }
    > .littleSimilar {
      background: var(--Green-Lighter);
    }
    > .soSo {
      background: var(--Warning-Medium);
    }
  }
</style>
