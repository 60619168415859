/* eslint-disable sort-keys */
import type {LanguagesList} from '@/definitions/langoid'

export const languages: LanguagesList[] = [
  {id: 1, iso: 'eng', name: 'English', status: 2, original: 'English'},
  {id: 2, iso: 'srp', name: 'Serbian', status: 2, original: 'Srpski'},
  {id: 3, iso: 'ceb', name: 'Cebuano', status: 2},
  {id: 4, iso: 'tgl', name: 'Tagalog', status: 2},
  {id: 5, iso: 'spa', name: 'Spanish', status: 2},
  {id: 6, iso: 'cmn', name: 'Chinese', status: 2},
  {id: 7, iso: 'jpn', name: 'Japanese', status: 1},
  {id: 8, iso: 'kor', name: 'Korean', status: 1},
  {id: 9, iso: 'deu', name: 'German', status: 1},
  {id: 10, iso: 'fra', name: 'French', status: 1},
  {id: 11, iso: 'ita', name: 'Italian', status: 1},
  {id: 12, iso: 'rus', name: 'Russian', status: 2},
  {id: 13, iso: 'arb', name: 'Arabic', status: 2, dir: 'rtl'},
  {id: 14, iso: 'ben', name: 'Bengali', status: 1},
  {id: 15, iso: 'hin', name: 'Hindi', status: 1},
  {id: 16, iso: 'ell', name: 'Greek', status: 1},
  {id: 17, iso: 'por', name: 'Portuguese', status: 1},
  {id: 18, iso: 'vie', name: 'Vietnamese', status: 0},
  {id: 19, iso: 'tur', name: 'Turkish', status: 0},
  {id: 20, iso: 'ind', name: 'Indonesian', status: 2},
  {id: 21, iso: 'pol', name: 'Polish', status: 0},
  {id: 24, iso: 'bul', name: 'Bulgarian', status: 2},
  {id: 27, iso: 'ron', name: 'Romanian', status: 0},
  {id: 41, iso: 'ukr', name: 'Ukrainian', status: 2},
  {id: 44, iso: 'yor', name: 'Yoruba', status: 0},
  {id: 49, iso: 'nld', name: 'Dutch', status: 0},
  {id: 55, iso: 'ces', name: 'Czech', status: 2},
  {id: 70, iso: 'heb', name: 'Hebrew', status: 2, dir: 'rtl'},
  {id: 71, iso: 'slk', name: 'Slovak', status: 2},
  {id: 78, iso: 'hye', name: 'Armenian', status: 2},
  {id: 84, iso: 'bel', name: 'Belarusian', status: 2 },
  {id: 85, iso: 'slv', name: 'Slovenian', status: 2},
  {id: 86, iso: 'mkd', name: 'Macedonian', status: 2},
].sort((a, b) => a.name.localeCompare(b.name)) as LanguagesList[]
