<script lang='ts'>
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { Route as RouteOrig, Router } from 'svelte-routing'
  import { SvelteToast } from '@zerodevx/svelte-toast'

  import { EXECUTION_TIME_HACK, HTTP_STATUS_OK, RTL_LANGUAGES } from '@/config/constants'

  import { isConsentRequired, isInputElement, toInt, toLevel } from '@/helpers/mixHelpers'

  import BottomMenu from '@/components/menu/BottomMenu.svelte'
  import CockpitMenu from '@/components/menu/CockpitMenu.svelte'
  import ToolsMenu from '@/components/menu/ToolsMenu.svelte'
  import VerticalMenu from '@/components/menu/VerticalMenu.svelte'
  import CookieConsent from '@/components/ui/CookieConsent.svelte'

  import Menu from './components/menu/Menu.svelte'
  import ActivityLogger from './components/special/ActivityLogger.svelte'
  import { API_URL, getCookie, getIsoFromId } from './helpers/apiCall'
  import { handleRejections, logOnServer } from './helpers/errorsHelper'
  import BulkAddWordsPage from './pages/advanced/bulkAddWord.svelte'
  import FullLessonViewPage from './pages/advanced/fullLessonView.svelte'
  import ImportedReadPage from './pages/advanced/importedReadPage.svelte'
  import ImportTextPage from './pages/advanced/importTextPage.svelte'
  import ScriptLearn from './pages/advanced/scripts/ScriptLearn.svelte'
  import ScriptsList from './pages/advanced/scripts/ScriptsList.svelte'
  import ScriptTranslate from './pages/advanced/scripts/ScriptTranslate.svelte'
  import VocabularyComparePage from './pages/advanced/vocabularyCompare.svelte'
  import WordPropsPatternsPage from './pages/advanced/wordPropsPatterns.svelte'
  import ForgotPasswordPage from './pages/auth/forgot-password.svelte'
  import LoginPage from './pages/auth/login.svelte'
  import MailVerify from './pages/auth/mail-verify.svelte'
  import RegisterPage from './pages/auth/register.svelte'
  import ForumCategory from './pages/forum/category.svelte'
  import ForumEdit from './pages/forum/edit.svelte'
  import FilteredForum from './pages/forum/filteredForum.svelte'
  import ForumMain from './pages/forum/main.svelte'
  import ForumTopic from './pages/forum/topic.svelte'
  import ForumTranslate from './pages/forum/translate.svelte'
  import GoalPage from './pages/goals/goal.svelte'
  import GoalsPage from './pages/goals/index.svelte'
  import GuidesPage from './pages/guide.svelte'
  /*import Home from './pages/home.svelte'*/
  import HomeNotLogged from './pages/homeNotLogged.svelte'
  import LanguagePage from './pages/languagePage/languagePage.svelte'
  import WordsExplorerPage from './pages/languagePage/wordsExplorer.svelte'
  import WordsFormsPage from './pages/languagePage/wordsForms.svelte'
  import ListenPage from './pages/menu/listen.svelte'
  import SocialPage from './pages/menu/social.svelte'
  import TasksPage from './pages/menu/tasks.svelte'
  import TestsPage from './pages/menu/tests.svelte'
  import MissionsPage from './pages/missions.svelte'
  import MustBeLoggedIn from './pages/mustBeLogged.svelte'
  import PlansPage from './pages/plans.svelte'
  import ResetPage from './pages/reset.svelte'
  import EditProfilePage from './pages/social/editProfile.svelte'
  import EditSettingsPage from './pages/social/editSettings.svelte'
  import MyActivities from './pages/social/myActivities.svelte'
  import ProfilePage from './pages/social/profile.svelte'
  import RangListPage from './pages/social/rang-list.svelte'
  import SearchPage from './pages/social/search.svelte'
  import StatsPage from './pages/social/stats.svelte'
  import StatsLeveledPage from './pages/social/stats-leveled.svelte'
  import WordProperties from './pages/word-properties.svelte'

  import './global.scss'

  import { ApiValidateToken } from '@/definitions/apiMix'
  import { TaskType } from '@/definitions/langoid'
  import { initI18n } from '@/libs/i18n'
  import ScriptPractice from '@/pages/advanced/scripts/ScriptPractice.svelte'
  import Design from '@/pages/design.svelte'
  import ChatHome from '@/pages/languagePage/chat.svelte'
  import ChatUser from '@/pages/languagePage/chatUser.svelte'
  import ChatUsersSearch from '@/pages/languagePage/chatUsersSearch.svelte'
  import LessonPage from '@/pages/languagePage/lessonPage.svelte'
  import LevelPage from '@/pages/languagePage/levelPage.svelte'
  import ListenWordsPage from '@/pages/languagePage/listenWords.svelte'
  import VocabularyPage from '@/pages/languagePage/vocabulary.svelte'
  import LearnReadingPage from '@/pages/reading/learn.svelte'
  import ReadingProgressPage from '@/pages/reading/progress.svelte'
  import TaskCorrectPage from '@/pages/tasks/correct.svelte'
  import TaskCreatePage from '@/pages/tasks/create.svelte'
  import TaskFindPage from '@/pages/tasks/find.svelte'
  import TaskSelectPage from '@/pages/tasks/select.svelte'
  import CombinedTestsPage from '@/pages/tests/combined.svelte'
  import QualificationTestsPage from '@/pages/tests/qualification.svelte'
  import VocabularyCheckerPage from '@/pages/tests/vocabularyChecker.svelte'
  import { lastFocusedInput, storeKeys, userStorredConfig } from '@/store'

  export let url = ''
  let logged: boolean
  const Route = RouteOrig
  jwtCheck()

  function jwtCheck () {
    const jwt = getCookie('jwt')
    if (jwt) {
      fetch(`${API_URL}/auth/validateToken`, {
        body: JSON.stringify({
          jwt,
          path: window.location.pathname
        }),
        method: 'POST'
      })
        .then((result) => {
          if (result.status === HTTP_STATUS_OK) {
            result.json().then((data: ApiValidateToken) => {
              logged = true
              initI18n(getIsoFromId(data.data.id_lang_interface))
              // navigate('/', { replace: true })
            })
          } else {
            logged = false
            initI18n()
          }
        })
        .catch((e) => {
          console.error(e, API_URL)
        })
    } else {
      logged = false
      localStorage.removeItem(storeKeys.userConfig)
      initI18n()
    }
  }

  setTimeout(() => {
    if (!document.body.dir) {
      document.body.dir = RTL_LANGUAGES.includes(get(userStorredConfig).id_lang_interface)
        ? 'rtl'
        : 'ltr' // right-to-left vs. left-to-right
    }
  }, EXECUTION_TIME_HACK)

  const toTaskType = (n: string) => n as TaskType

  // Handling virtual keyboard - todo: check if this can be deleted

  onMount(() => {
    // When the component mounts, attach global event listeners for click and focus
    window.addEventListener('click', updateInput)
    window.addEventListener('focusin', updateInput)

    function updateInput (event: MouseEvent | FocusEvent) {
      // Check if the event's target is an input or textarea
      const target = event.target as HTMLInputElement | HTMLTextAreaElement
      if (isInputElement(target)) {
        // If it is, set the store value to this element
        lastFocusedInput.set(target)
      }
    }

    // Be sure to clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('click', updateInput)
      window.removeEventListener('focusin', updateInput)
    }
  })
  const theme = localStorage.getItem('theme')
  if (theme) {
    document.body.setAttribute('data-theme', theme)
  }
</script>
<SvelteToast />
<svelte:window on:error={logOnServer} on:unhandledrejection={handleRejections} />
<Router {url}>
  {#if typeof logged !== 'undefined'}
    {#if logged}
      <Menu />
      <ActivityLogger />
    {/if}
    <main class={'main-content ' + (logged ? '-logged' : '-notLogged')}>
      <Route path='/reset'>
        <ResetPage />
      </Route>
      {#if !logged}
        <Route path='/'>
          <HomeNotLogged />
        </Route>
        <Route path='/login'>
          <LoginPage />
        </Route>
        <Route path='/register'>
          <RegisterPage />
        </Route>
        <Route path='/forgot-password'>
          <ForgotPasswordPage />
        </Route>
        <Route path='/verify'>
          <MailVerify />
        </Route>

        <!-- FOR GOOGLE SEO -->
        <Route path='/:iso/t/:slug/:topicId' let:params>
          <ForumTopic iso={params.iso} topicId={toInt(params.topicId)} />
        </Route>
        <Route path='/:iso/t/:slug/:topicId/:page' let:params>
          <ForumTopic iso={params.iso} page={toInt(params.page)} topicId={toInt(params.topicId)} />
        </Route>
        <Route path='/:iso/c/:slug/:categoryId' let:params>
          <ForumCategory category={toInt(params.categoryId)} iso={params.iso} />
        </Route>
        <Route path='/:iso/c/:slug/:categoryId/:page' let:params>
          <ForumCategory category={toInt(params.categoryId)} iso={params.iso} page={toInt(params.page)} />
        </Route>
        <Route path='/:iso/forum' let:params>
          <FilteredForum group='latest' iso={params.iso} />
        </Route>
        <!-- To catch all routes that are not logged in, but are not in the list above -->
        <Route component={MustBeLoggedIn} path=':path' />
        <Route component={MustBeLoggedIn} path=':path/*' />
        {#if isConsentRequired()}
          <CookieConsent />
        {/if}
      {/if}
      {#if logged}
        <div class='main-left -logged'>
          <VerticalMenu />
        </div>
        <div class='main-right -logged'>
          <Route component={Design} path='/design' />
          <!-- Guides -->
          <Route path=':iso/guides' let:params>
            <GuidesPage />
          </Route>
          <!-- Goals -->
          <Route path='goals/:id' let:params>
            <GoalPage id={toInt(params.id)} />
          </Route>
          <Route path='goals/'>
            <GoalsPage />
          </Route>
          <Route path=':iso/goals' let:params>
            <GoalsPage iso={params.iso} />
          </Route>

          <!-- Social & Profile -->
          <Route path='/settings'>
            <EditSettingsPage />
          </Route>
          <Route path='/profile/:userId' let:params>
            <ProfilePage userId={toInt(params.userId)} />
          </Route>
          <Route path='/edit-profile'>
            <EditProfilePage />
          </Route>
          <Route path='/search-users'>
            <SearchPage />
          </Route>
          <Route path='/rang-list'>
            <RangListPage />
          </Route>
          <Route path=':iso/stats-leveled'>
            <StatsLeveledPage />
          </Route>
          <Route path='/my-activities'>
            <MyActivities />
          </Route>

          <Route path='/missions/:id' let:params>
            <MissionsPage id={toInt(params.id)} />
          </Route>
          <Route path='/missions'>
            <MissionsPage />
          </Route>
          <Route path='/social'>
            <SocialPage />
          </Route>

          <!-- My vocabulary -->
          <Route path=':iso/words-explorer/:group' let:params>
            <WordsExplorerPage group={params.group} iso={params.iso} />
          </Route>

          <!-- Other pages -->
          <Route path=':iso/words-forms'>
            <WordsFormsPage />
          </Route>
          <Route path=':iso/advanced/import' let:params>
            <ImportTextPage iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/import/:id' let:params>
            <ImportedReadPage id={toInt(params.id)} iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/word-props-patterns'>
            <WordPropsPatternsPage />
          </Route>
          <Route path=':iso/advanced/vocabulary-compare'>
            <VocabularyComparePage />
          </Route>
          <Route path=':iso/advanced/language-scripts' let:params>
            <ScriptsList iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/learnScript/:id' let:params>
            <ScriptLearn id={toInt(params.id)} iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/translateScript/:id' let:params>
            <ScriptTranslate id={toInt(params.id)} iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/practiceScript/:id' let:params>
            <ScriptPractice id={toInt(params.id)} iso={params.iso} />
          </Route>
          <Route path=':iso/advanced/bulk-add-words'>
            <BulkAddWordsPage />
          </Route>
          <Route path=':iso/advanced/full-lesson-view/' let:params>
            <FullLessonViewPage iso={params.iso} />
          </Route>

          <Route path=':iso/home/:rand' let:params>
            <LanguagePage iso={params.iso} rand={toInt(params.rand)} />
          </Route>
          <Route path=':iso/home' let:params>
            <LanguagePage iso={params.iso} />
          </Route>

          <Route path=':iso' let:params>
            <LanguagePage iso={params.iso} />
          </Route>
          <Route path='/' let:params>
            <LanguagePage iso={params.iso} />
          </Route>

          <Route path='/forum-edit-reply/:topicId/:replyId/:language' let:params>
            <ForumEdit language={toInt(params.language)} replyId={toInt(params.replyId)} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/forum-edit-reply/:topicId/:replyId' let:params>
            <ForumEdit replyId={toInt(params.replyId)} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/forum-edit/:topicId/:language' let:params>
            <ForumEdit language={toInt(params.language)} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/forum-edit/:topicId' let:params>
            <ForumEdit topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/forum-translate/:topicId/:replyId' let:params>
            <ForumTranslate replyId={toInt(params.replyId)} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/forum-translate/:topicId' let:params>
            <ForumTranslate topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/:iso/forum/categories' let:params>
            <ForumMain iso={params.iso} />
          </Route>

          <!-- FORUM OLD PAGES AND short urls -->
          <Route path='/:iso/forum/:category' let:params>
            <ForumCategory category={toInt(params.category)} iso={params.iso} />
          </Route>
          <Route path='/:iso/forum/:category/:topicId/:slug' let:params>
            <ForumTopic iso={params.iso} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/:iso/forum/:category/:topicId' let:params>
            <ForumTopic iso={params.iso} topicId={toInt(params.topicId)} />
          </Route>
          <Route path=':iso/forum/latest' let:params>
            <FilteredForum group='latest' iso={params.iso} />
          </Route>
          <Route path='/forum/untranslated-posts'>
            <FilteredForum group='untranslated' />
          </Route>
          <Route path=':iso/forum/untranslated-posts' let:params>
            <FilteredForum group='untranslated' iso={params.iso} />
          </Route>
          <Route path='/forum/user-bookmarks'>
            <FilteredForum group='bookmarks' />
          </Route>
          <Route path=':iso/forum/user-bookmarks' let:params>
            <FilteredForum group='bookmarks' iso={params.iso} />
          </Route>

          <!-- Learning page -->
          <Route path=':iso/reading-progress' let:params>
            <ReadingProgressPage iso={params.iso} />
          </Route>
          <Route path=':iso/reading-learn' let:params>
            <LearnReadingPage iso={params.iso} />
          </Route>
          <Route path='/vocabulary'>
            <VocabularyPage />
          </Route>
          <Route path=':iso/vocabulary' let:params>
            <VocabularyPage />
          </Route>
          <Route path=':iso/tests/:batchType' let:params>
            <CombinedTestsPage batchType={params.batchType} iso={params.iso} />
          </Route>
          <Route path=':iso/qualification-tests' let:params>
            <QualificationTestsPage />
          </Route>
          <Route path=':iso/vocabulary-checker' let:params>
            <VocabularyCheckerPage />
          </Route>
          <Route path=':iso/tests/:batchType/refresh' let:params>
            <CombinedTestsPage batchType={params.batchType} iso={params.iso} />
          </Route>
          <Route path=':iso/vocabulary-checker/refresh' let:params>
            <VocabularyCheckerPage />
          </Route>
          <Route path=':iso/chat/users' let:params>
            <ChatUsersSearch iso={params.iso} />
          </Route>
          <Route path=':iso/chat/:id' let:params>
            <ChatUser id={params.id} iso={params.iso} />
          </Route>
          <Route path=':iso/chat' let:params>
            <ChatHome iso={params.iso} />
          </Route>
          <Route path=':iso/listen-words' let:params>
            <ListenWordsPage iso={params.iso} playType='word' />
          </Route>
          <Route path=':iso/listen-sentences' let:params>
            <ListenWordsPage iso={params.iso} playType='sentence' />
          </Route>

          <!-- Tasks -->
          <Route path=':iso/task' let:params>
            <TasksPage iso={params.iso} />
          </Route>
          <Route path=':iso/task/:type/create' let:params>
            <TaskCreatePage iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/create/:id' let:params>
            <TaskCreatePage id={params.id} iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/correct/:id' let:params>
            <TaskCorrectPage id={toInt(params.id)} iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/find/:id' let:params>
            <TaskFindPage id={params.id} iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/find' let:params>
            <TaskFindPage iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/select' let:params>
            <TaskSelectPage iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/select/:id' let:params>
            <TaskSelectPage id={params.id} iso={params.iso} type={toTaskType(params.type)} />
          </Route>
          <Route path=':iso/task/:type/select/:id/:target' let:params>
            <TaskSelectPage id={params.id} iso={params.iso} target={params.target} type={toTaskType(params.type)} />
          </Route>

          <!-- LESSONS -->
          <Route path=':iso/lessons' let:params>
            <LevelPage iso={params.iso} />
          </Route>
          <Route path=':iso/lessons/:level' let:params>
            <LevelPage iso={params.iso} level={toLevel(params.level)} />
          </Route>
          <Route path=':iso/lessons/:level' let:params>
            <LevelPage iso={params.iso} level={toLevel(params.level)} />
          </Route>
          <Route path=':iso/lessons/:level/:lessonId' let:params>
            <LessonPage iso={params.iso} lessonId={params.lessonId} level={params.level} />
          </Route>

          <!-- OTHER -->
          <Route path='stats'>
            <StatsPage />
          </Route>
          <Route path='plans'>
            <PlansPage />
          </Route>
          <Route path=':iso/word-properties' let:params>
            <WordProperties iso={params.iso} />
          </Route>
          <Route path=':iso/tests' let:params>
            <TestsPage iso={params.iso} />
          </Route>
          <Route path=':iso/listen' let:params>
            <ListenPage iso={params.iso} />
          </Route>

          <!-- FOR GOOGLE SEO -->
          <Route path='/:iso/t/:slug/:topicId' let:params>
            <ForumTopic iso={params.iso} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/:iso/t/:slug/:topicId/:page' let:params>
            <ForumTopic iso={params.iso} page={toInt(params.page)} topicId={toInt(params.topicId)} />
          </Route>
          <Route path='/:iso/c/:slug/:categoryId' let:params>
            <ForumCategory category={toInt(params.categoryId)} iso={params.iso} />
          </Route>
          <Route path='/:iso/c/:slug/:categoryId/:page' let:params>
            <ForumCategory category={toInt(params.categoryId)} iso={params.iso} page={toInt(params.page)} />
          </Route>
          <Route path='/:iso/forum' let:params>
            <FilteredForum group='latest' iso={params.iso} />
          </Route>
        </div>
      {/if}
    </main>
    {#if logged}
      <BottomMenu />
      <CockpitMenu />
      <ToolsMenu />
    {/if}
  {:else}
    <img alt='Loading' src='/images/loader.gif' />
  {/if}
</Router>
<style lang='scss'>
  .main-content {
    display: flex;
    gap: 1.6rem;
    align-items: flex-start;
    width: 120rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 3.2rem 0;

    &.-notLogged {
      padding: 1.6rem;
      background: var(--main-background);
    }
  }

  .main-left {
    &.-logged {
      position: sticky;
      top: 0;
      overflow-y: auto;
      width: 28.4rem;
      max-height: 100vh;
    }
  }

  .main-right {
    display: flex;
    flex-direction: column;

    &.-logged {
      gap: 1.6rem;
      width: 100%;
      max-width: 88.4rem;
    }
  }

  @media (max-width: 768px) {
    .main-content.-logged {
      gap: 0;
      width: 100%;
      margin: 0 auto;
      padding: 0;
    }

    .main-left.-logged {
      display: none;
    }

    .main-right.-logged {
      width: 100%;
      padding: 1.6rem;
    }
  }
</style>
