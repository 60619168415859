import { get } from 'svelte/store'

import { ISO_LENGTH } from '@/config/constants'

import { calculateLocationGroup } from '@/helpers/urlHelpers'

import { _ } from '@/libs/i18n'

export const setMeta = (menuGroups: string[], locationGroup: string) => {
  // todo - for pages where we need to get this dinamically find solution (forum for example)
  let description = ''
  let canonical = ''
  let robots = ''
  const image = 'https://langoid.com/img/main.png'
  const title = getTitle(menuGroups, locationGroup)

  if (locationGroup === 'home') {
    canonical = '/'
  } else if (menuGroups[0] === 'auth') {
    robots = 'noindex, nofollow'
  }

  if (!description) {
    description = title
  }
  if (!canonical) {
    canonical = window.location.pathname
  }

  canonical = 'https://langoid.com' + canonical
  if (!robots) {
    robots = 'index, follow'
  }

  // console.log('META: ', locationGroup, menuGroups.slice(1), title, description)
  document.title = get(_)(title)
  document.querySelector('meta[name="description"]')?.setAttribute('content', description)
  document.querySelector('meta[property="og:title"]')?.setAttribute('content', title)
  document.querySelector('meta[property="og:description"]')?.setAttribute('content', description)
  document.querySelector('meta[property="og:image"]')?.setAttribute('content', image)
  document.querySelector('link[rel="canonical"]')?.setAttribute('href', canonical)
  document.querySelector('meta[name="robots"]')?.setAttribute('content', robots)
}

export const getMenuGroups = (currentLocation: string) => {
  const menuGroups = currentLocation.split('/')
  const locationGroup = calculateLocationGroup(currentLocation, menuGroups)
  return { locationGroup, menuGroups }
}

export const getTitle = (menuGroups: string[], locationGroup: string) => {

  let title = ''
  if (menuGroups[0] === 'auth') {
    switch (menuGroups[1]) {
      case 'login':
      case 'register':
      case 'verifyEmail':
      case 'forgotPassword':
        title = 'auth.' + menuGroups[1]
        break
    }
  } else if (locationGroup === 'lessons') {
    title = 'lessonsPage.title'
  } else if (locationGroup === 'read') {
    title = 'levelPage.learnToRead'
  } else if (locationGroup === 'chat') {
    title = 'levelPage.chat'
  } else if (locationGroup === 'goals') {
    title = 'levelPage.goals'
  } else if (locationGroup === 'guides') {
    title = 'levelPage.guides'
  } else if (locationGroup === 'forum') {
    title = 'forum.forum'
  } else if (locationGroup === 'missions') {
    title = 'menu.missions'
  } else if (locationGroup === 'words-explorer') {
    title = 'levelPage.wordsExplorer'
  } else if (menuGroups[0].length === ISO_LENGTH) {
    switch (menuGroups[1]) {
      case 'chat':
        title = 'chat'
        break
      case 'forum':
        title = 'forum'
        break
    }
  } else if (locationGroup === 'tests') {
    title = 'levelPage.tests'
    if (menuGroups[3] === 'srs') {
      title = 'levelPage.testsSentences'
    } else if (menuGroups[3] === 'words') {
      title = 'levelPage.testsWords'
    } else if (menuGroups[3] === 'sentences') {
      title = 'levelPage.testsAudioSentences'
    } else if (menuGroups[3] === 'customWords') {
      title = 'levelPage.myWords'
    } else if (menuGroups[3] === 'customSentences') {
      title = 'levelPage.mySentences'
    }
  } else if (locationGroup === 'exercise') {
    title = 'levelPage.exercises'
    if (['translate', 'story', 'listen', 'read'].includes(menuGroups[3])) {
      title = 'tasks.' + menuGroups[3]
    }
  } else if (locationGroup === 'listen') {
    title = 'levelPage.listen'
    if (menuGroups[2] === 'listen-words') {
      title = 'levelPage.listenWords'
    } else if (menuGroups[2] === 'listen-sentences') {
      title = 'levelPage.listenSentences'
    }
  } else if (locationGroup === 'search-users') {
    title = 'menu.searchUsers'
  } else if (locationGroup === 'notifications') {
    title = 'menu.notifications'
  } else if (locationGroup === 'profile') {
    title = 'menu.myProfile'
  } else if (locationGroup === 'settings') {
    title = 'menu.settings'
  } else if (locationGroup === 'home') {
    title = 'home.title'
  }

  if (!title) {
    title = 'home.title'
  }
  return title
}
