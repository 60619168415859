<script lang='ts'>
  // export let iso = ''
  export let original: any[] = []
  export let translated: any[] = []
</script>
<table>
  <tr>
    <th>Original</th>
    <th>Translation</th>
  </tr>
  {#each original as item}
    <tr>
      <td>{translated[item.line_order]?.content}</td>
      <td>{item.content}</td>
    </tr>
  {/each}
</table>
